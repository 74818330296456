import {
  Box,
  CardHeader,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Tooltip,
  styled,
  Chip,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import { Fragment } from 'react';
import {
  CheckCircleTwoTone,
  CloseTwoTone,
  CropSquareTwoTone,
  LoopTwoTone,
} from '@mui/icons-material';

const TimelineWrapper = styled(Timeline)(
  ({ theme }) => `
      margin-left: ${theme.spacing(2)};
  
      .MuiTimelineDot-root {
        left: -${theme.spacing(2)};
        margin-top: 0;
        top: ${theme.spacing(0.5)};
      }
      
      .MuiTimelineContent-root {
        padding-left: ${theme.spacing(4)};
      }
      
      .MuiFormControlLabel-root {
        margin-left: -${theme.spacing(0.7)};
      }
      
      .MuiFormControlLabel-label {
        color: ${theme.colors.alpha.black[50]};
      }
  `,
);

const CheckboxWrapper = styled(Checkbox)(
  ({ theme }) => `
      padding: ${theme.spacing(0.5)};
  `,
);

const handleUpdateProjectApprovalStatus = async (projectId, status) => {
  // API call to update project approval status
};
const AllActions = ({ project }) => {
  return (
    <div className="flex items-center space-x-2">
      <Chip
        label="Forward"
        color="success"
        variant="outlined"
        sx={{
          m: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'success.light',
            color: 'white',
          },
        }}
        onClick={() => {
          window.confirm('Are you sure you want to forward this project?') &&
            window.prompt('Enter secured OTP to proceed') &&
            handleUpdateProjectApprovalStatus(project.id, 'Forward');
        }}
      />
      <Chip
        label="Revert"
        color="warning"
        variant="outlined"
        sx={{
          m: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'warning.light',
            color: 'white',
          },
        }}
        onClick={() => {
          window.confirm('Are you sure you want to revert this project?') &&
            window.prompt('Enter secured OTP to proceed') &&
            handleUpdateProjectApprovalStatus(project.id, 'Revert');
        }}
      />

      <Chip
        label="Reject"
        color="error"
        variant="outlined"
        sx={{
          m: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'error.light',
            color: 'white',
          },
        }}
        onClick={() => {
          window.confirm('Are you sure you want to reject this project?') &&
            window.prompt('Enter secured OTP to proceed') &&
            handleUpdateProjectApprovalStatus(project.id, 'Reject');
        }}
      />
      <Chip
        label="Non Relevant"
        color="secondary"
        variant="outlined"
        sx={{
          m: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'secondary.light',
            color: 'white',
          },
        }}
        onClick={() => {
          window.confirm(
            'Are you sure you want to mark this project as non relevant?',
          ) &&
            window.prompt('Enter secured OTP to proceed') &&
            handleUpdateProjectApprovalStatus(project.id, 'Non Relevant');
        }}
      />
    </div>
  );
};

const ActionRequirePreviousApproval = () => {
  return (
    <Chip
      label="Require Previous Approval First"
      color="info"
      variant="outlined"
      sx={{
        m: 1,
      }}
    />
  );
};

function NewChecklist({ project, user }) {
  return (
    <Box>
      <CardHeader
        sx={{
          px: 0,
          pt: 0,
        }}
        action={
          <Tooltip arrow title="Refresh list">
            <IconButton size="small" color="primary">
              <RefreshTwoToneIcon />
            </IconButton>
          </Tooltip>
        }
        title="Checklist"
      />

      <TimelineWrapper>
        {project?.projectApprovalProgress?.map((approvalProgress, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                color={
                  approvalProgress?.status === 'Pending' ? 'primary' : 'success'
                }
              >
                {approvalProgress?.status === 'Pending' && (
                  <AssignmentTwoToneIcon />
                )}

                {approvalProgress?.status === 'Forward' && (
                  <CheckCircleTwoTone />
                )}
                {approvalProgress?.status === 'Revert' && <LoopTwoTone />}
                {approvalProgress?.status === 'Reject' && (
                  <CloseTwoTone color="error" />
                )}

                {approvalProgress?.status === 'Non Relevant' && (
                  <CropSquareTwoTone color="secondary" />
                )}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant="h4"
                sx={{
                  pb: 2,
                }}
              >
                {approvalProgress?.level}
              </Typography>
              {user?.isAdmin && <AllActions project={project} />}
              <Fragment>
                {approvalProgress?.status === 'Pending' &&
                  user?.role === approvalProgress?.level && (
                    <AllActions project={project} />
                  )}
              </Fragment>
            </TimelineContent>
          </TimelineItem>
        ))}

        {/* <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <AccountTreeTwoToneIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant="h4"
              sx={{
                pb: 2,
              }}
            >
              Tally Clerk
            </Typography>
            <Chip
              label="Require Previous Approval First"
              color="info"
              variant="outlined"
              sx={{
                m: 1,
              }}
            />
            <FormGroup>
              <FormControlLabel
                control={<CheckboxWrapper color="primary" name="checkedC" />}
                label="Bills and Invoices Matched"
              />
              <FormControlLabel
                control={<CheckboxWrapper color="error" name="checkedC" />}
                label="Reject Bills and Invoices"
              />
            </FormGroup>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <AccountTreeTwoToneIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant="h4"
              sx={{
                pb: 2,
              }}
            >
              Cashier
            </Typography>
            <Chip
              label="Require Previous Approval First"
              color="info"
              variant="outlined"
              sx={{
                m: 1,
              }}
            />
            <FormGroup>
              <FormControlLabel
                control={<CheckboxWrapper color="primary" name="checkedC" />}
                label="Cash Drawer Balanced"
              />
              <FormControlLabel
                control={<CheckboxWrapper color="error" name="checkedC" />}
                label="Cash Drawer Unbalanced"
              />
            </FormGroup>
          </TimelineContent>
        </TimelineItem> */}
      </TimelineWrapper>
    </Box>
  );
}

export default NewChecklist;
