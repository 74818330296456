import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useEffect, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  useTheme,
  Typography,
  Chip,
  OutlinedInput,
  styled,
  InputAdornment,
  Button,
  InputLabel,
  Select,
  Autocomplete,
} from '@mui/material';
import Footer from 'src/components/Footer';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

import Switch from '@mui/material/Switch';
import UploadImage from 'src/components/UploadImage';
import Checklist from 'src/content/dashboards/Tasks/Checklist';
import Profile from 'src/content/dashboards/Tasks/Profile';
import TeamOverview from 'src/content/dashboards/Tasks/TeamOverview';
import NewChecklist from './NewChecklist';
import { Close } from '@mui/icons-material';
import { projectData } from 'src/data';
import { useNavigate } from 'react-router';

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      padding-right: ${theme.spacing(0.7)}
  `,
);

interface ProjectSchema {
  _id: string;
  projectCode: string;
  mainCategory: string;
  typeOfWork: string;
  workName: string;
  circle: string;
  ward: string;
  natureOfWork: string;
  department: string;
  description: string;
  fundType: string;
  budgetHead: string;
  functions: string;
  functionaries: string[];
  scheme: string;
  subScheme: string;
  estimatedCost: string;
  projectDurationStart: string;
  projectDurationEnd: string;
  ActualStartDate: string;
  ActualEndDate: string;
  contractor: string;
  approvalStatus: string;
  supportingDocuments: string[];
  remarks: string;
}

function ProjectChecklist() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState('EUR');

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const [project, setProject] = useState('');
  const [projects, setProjects] = useState<ProjectSchema[]>([]);
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(30);

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  const fetchMyProject = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/my-projects-list/${
          JSON.parse(localStorage.getItem('user') || '{}')?.id
        }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const data = await response.json();
      setProjects(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyProject();
  }, []);

  return (
    <>
      <Helmet>
        <title>Project Checklist</title>
      </Helmet>

      <Container maxWidth="lg" className="mt-10">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          // i want to display project name in the dropdown and navigate to project details page on select using project id
          options={projects?.map((project) => {
            return { label: project.workName, value: project._id };
          })}
          value={project}
          onChange={(event, newValue: any) => {
            console.log(newValue);
            // setProject(newValue.label);
            setProject(newValue.label);
            navigate('/management/project-details/' + newValue.value);
          }}
          // value={project}
          // onChange={(event, newValue) => {
          //   console.log(newValue);
          //   // setProject(newValue.label);
          //   // navigate('/management/project-details/' + newValue);
          // }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Project"
              placeholder="Start typing to search..."
            />
          )}
        />

        <h2 className="text-2xl  font-semibold mt-20">
          Recently Opened Projects
        </h2>
        <Grid item xs={12}>
          <Box p={4}>
            <TeamOverview />
          </Box>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default ProjectChecklist;
