export default function LocationBar() {
  return (
    <div className="bg-neutral-900">
      <div className="max-w-[85rem] px-4 py-2 sm:px-6 lg:px-8 mx-auto text-center">
        <span className="group inline-flex flex-wrap items-center bg-white/10 hover:bg-white/10 focus:outline-none focus:bg-white/10 border border-white/10 p-1 ps-4 rounded-full shadow-md">
          <p className=" text-white text-xs">
            India | XJ98+RR6, Victoria Layout, Bengaluru, Karnataka, 560007
          </p>{' '}
          -{' '}
          <a href="#" className="text-white text-xs underline">
            From your device
          </a>
        </span>
      </div>
    </div>
  );
}
