import { Link, useLocation } from 'react-router-dom';
import {
  Home,
  Innovation,
  Organisation,
  Project,
  Tender,
} from 'src/components/Icons';

export default function Header() {
  const location = useLocation();
  const menuitems = [
    {
      id: 1,
      name: 'Home',
      link: '/',
      submenus: [],
      isActive: false,
      icon: <Home />,
    },
    {
      id: 2,
      name: 'Projects',
      link: '/projects',
      submenus: [],
      isActive: false,
      icon: <Project />,
    },
    {
      id: 3,
      name: 'Organisation',
      link: '/organisation',
      submenus: [],
      isActive: false,
      icon: <Organisation />,
    },

    {
      id: 4,
      name: 'Tenders',
      link: '/tenders',
      submenus: [],
      isActive: false,
      icon: <Tender />,
    },

    {
      id: 5,
      name: 'Innovations',
      link: '/innovations',
      submenus: [],
      isActive: false,
      icon: <Innovation />,
    },
  ];
  return (
    <header className="w-full  ">
      <div className="m-2 flex flex-col space-y-2 items-center">
        <Link to="/">
          <img
            src="/static/logo_ksphc_eng.jpg"
            alt="Karnataka State Police Housing Corporation Limited"
            className="w-auto   inline-flex items-center  "
          />
        </Link>
      </div>
      {menuitems.some((item) => item.link === location.pathname) ? (
        <div className="my-2 mx-16">
          <nav
            aria-label="Tabs"
            className="relative z-0 flex border rounded-xl overflow-hidden "
            role="tablist"
          >
            {menuitems.map((menuitem) => (
              <Link
                aria-controls="bar-with-underline-1"
                className="hs-tab-active:border-b-blue-600 hs-tab-active:text-gray-900  min-w-0 flex-1 bg-white first:border-s-0 border-s border-b-2 py-4 px-4 text-gray-500 hover:text-gray-700 text-sm font-medium text-center overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none  active"
                data-hs-tab="#bar-with-underline-1"
                id="bar-with-underline-item-1"
                role="tab"
                type="button"
                to={`/?tab=${menuitem.id}`}
              >
                <div className="group items-center flex gap-x-5  rounded-lg p-2 ">
                  <div className="flex-shrink-0 size-5 ">{menuitem.icon}</div>
                  <div className="grow">
                    <p className="font-medium text-gray-800 ">
                      {menuitem.name}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </nav>
        </div>
      ) : null}
    </header>
  );
}
