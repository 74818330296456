import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useEffect, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  useTheme,
  Typography,
  Chip,
  OutlinedInput,
  styled,
  InputAdornment,
  Button,
  InputLabel,
  Select,
  Autocomplete,
} from '@mui/material';
import Footer from 'src/components/Footer';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import UploadImage from 'src/components/UploadImage';
import Checklist from 'src/content/dashboards/Tasks/Checklist';
import Profile from 'src/content/dashboards/Tasks/Profile';
import TeamOverview from 'src/content/dashboards/Tasks/TeamOverview';
import NewChecklist from 'src/content/applications/ProjectChecklist/NewChecklist';
import { useLocation } from 'react-router';
import UploadModal from '../../Forms/UploadModal';
import ProjectDrawer from 'src/content/applications/ProjectDetails/ProjectDrawer';

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      padding-right: ${theme.spacing(0.7)}
  `,
);
const label = {
  inputProps: {
    'aria-label': 'Switch demo',
  },
};

interface ProjectSchema {
  _id: string;
  projectCode: string;
  mainCategory: string;
  typeOfWork: string;
  workName: string;
  circle: string;
  ward: string;
  natureOfWork: string;
  department: string;
  description: string;
  fundType: string;
  budgetHead: string;
  functions: string;
  functionaries: string[];
  scheme: string;
  subScheme: string;
  estimatedCost: string;
  projectDurationStart: string;
  projectDurationEnd: string;
  ActualStartDate: string;
  ActualEndDate: string;
  contractor: string;
  approvalStatus: string;
  supportingDocuments: string[];
  remarks: string;
}

export default function MyProjectDetails() {
  const theme = useTheme();
  const location = useLocation();
  // get id from location
  const projectId = location.pathname.split('/').pop();
  const [loading, setLoading] = useState(false);

  const [project, setProject] = useState<ProjectSchema | null>(null);

  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(30);

  const fetchMyProjectDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/my-project-details/${
          JSON.parse(localStorage.getItem('user') || '{}').id
        }/${projectId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const data = await response.json();
      setProject(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyProjectDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Project Details</title>
      </Helmet>

      <Container maxWidth="lg" className="mt-10">
        <div className="flex items-start justify-between space-x-10">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            <Grid item xs={12}>
              <Card>
                <Chip
                  label="Status: Pending Approval"
                  color="warning"
                  variant="filled"
                  sx={{
                    m: 1,
                  }}
                />

                {/* <CardHeader
                  sx={{
                    fontSize: '2.5rem',
                  }}
                  title=
                /> */}
                <h1 className="text-2xl font-semibold text-left p-3">
                  {`${project?.workName} | Project Code: ${project?.projectCode}`}
                </h1>
                <Divider />

                <CardContent>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        background: `${theme.colors.alpha.black[5]}`,
                      }}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={12} md={6}>
                          <Box
                            p={4}
                            sx={{
                              background: `${theme.colors.alpha.white[70]}`,
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                px: 2,
                                pb: 2,
                              }}
                            >
                              <UploadModal />
                            </Typography>
                            <Checklist project={project} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box p={4}>
                            <div className="w-full border  rounded-xl">
                              <div className="grid divide-x-2 grid-cols-2">
                                <div className="flex flex-col p-4 space-y-5 divide-y-2">
                                  <h1 className="text-2xl font-semibold">
                                    Project Info
                                  </h1>

                                  <ProjectDrawer intent="documents">
                                    <div className="text-lg">
                                      View/Upload Documents
                                    </div>
                                  </ProjectDrawer>
                                  <ProjectDrawer intent={'pictures'}>
                                    <div className="text-lg">
                                      View/Upload Pictures
                                    </div>
                                  </ProjectDrawer>

                                  <ProjectDrawer intent={'drawings'}>
                                    <div className="text-lg">
                                      View/Upload Drawings
                                    </div>
                                  </ProjectDrawer>

                                  <ProjectDrawer intent={'qc-reports'}>
                                    <div className="text-lg">
                                      View/Upload Q.C. Reports
                                    </div>
                                  </ProjectDrawer>

                                  <ProjectDrawer intent={'site-visits'}>
                                    <div className="text-lg">Site Visits</div>
                                  </ProjectDrawer>
                                </div>
                                <div className="flex flex-col p-4 space-y-5 divide-y-2">
                                  <h1 className="text-2xl font-semibold">
                                    Q.C. Format
                                  </h1>
                                  <div className="text-lg">
                                    Coarse Aggregate
                                  </div>
                                  <div className="text-lg">Fine Aggregate</div>
                                  <div className="text-lg">
                                    Physical Tests on Cement
                                  </div>
                                  <div className="text-lg">
                                    Sand for Deleterious Materials
                                  </div>
                                  <div className="text-lg">
                                    Test Reports on Steel
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Box>
                          <Box p={4}>
                            <Profile project={project} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
    </>
  );
}
