import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Select,
  SelectChangeEvent,
  OutlinedInput,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import Creatable from 'react-select/creatable';
import { Close, Save, SaveAltSharp } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
// const names = [
//   'Sanjay Gupta - Deputy Executive Engineer',
//   'Rajesh Kumar - Assistant Engineer',
//   'Sanjay Kumar - Junior Engineer',
//   'Suresh Kumar - Deputy Executive Engineer',
//   'Omar Abdullah - Assistant Engineer',
//   'Manoj Kumar - Deputy Executive Engineer',
//   'Balaji Rao - Junior Executive Engineer',
//   'Ramanathan - Junior Engineer',
//   'S Balasubramaniam - Junior Engineer',
//   'B Srinivas - Executive Engineer',
// ];
const mainCategories = ['Roads', 'Buildings', 'Bridges', 'Utilities', 'Others'];
const typesOfWork = [
  {
    id: 'Roads',
    options: ['Road Construction', 'Road Repair', 'Others'],
  },
  {
    id: 'Buildings',
    options: ['Building Construction', 'Building Repair', 'Others'],
  },
  {
    id: 'Bridges',
    options: ['Bridge Construction', 'Bridge Repair', 'Others'],
  },
  {
    id: 'Utilities',
    options: ['Utilities Construction', 'Utilities Repair', 'Others'],
  },
  {
    id: 'Others',
    options: ['Others'],
  },
];
const circles = ['Nakkalagutta', 'Circle 2', 'Circle 3', 'Others'];
const wards = ['Ward 33', 'Ward 34', 'Ward 35', 'Others'];
const natureOfWorks = ['Capital', 'Maintenance', 'Repair'];
const departments = ['Public Health', 'Public Works', 'Housing', 'Others'];
const fundTypes = [
  'MPLADS',
  'State Government Funds',
  'Central Government Funds',
  'Others',
];
const functions = [
  'Repairs of Storm Water Drains',
  'Road Construction',
  'Others',
];
const schemes = ['SMART CITY', 'AMRUT', 'PMGSY', 'Others'];
const subSchemes = ['Govt.', 'PPP', 'Private', 'Others'];
const approvalStatuses = ['Pending', 'Approved', 'Rejected'];

export default function CreateProject() {
  const navigate = useNavigate();
  const [initialFunctionaries, setInitialFunctionaries] = useState([]);
  //   const [functionaries, setFunctionaries] = useState();

  const [contractors, setContractors] = useState([]);

  const [data, setData] = useState({
    projectCode: `PRJ-${Math.floor(Math.random() * 1000000)}`,
    mainCategory: 'Buildings',
    typeOfWork: 'Building Construction',
    workName: 'Public Dental Clinic',
    circle: 'Circle 2',
    ward: 'Ward 34',
    natureOfWork: 'Capital',
    department: 'Public Health',
    description:
      'This is a project involving health and safety of the public. A 3 standard floor construction is required. All the details are mentioned below in the documents along with the finances and construction quality requirements & Checks. ',
    fundType: 'State Government Funds',
    budgetHead: 'Mr. Rajamouli S',
    functions: 'Others',
    functionaries: [
      '669682fe225983176496f5c5',
      '6696837d225983176496f5c8',
      '669683b0225983176496f5cb',
    ],
    scheme: 'SMART CITY',
    subScheme: 'Govt.',
    estimatedCost: '18500000',
    projectDurationStart: '2024-07-20',
    projectDurationEnd: '2025-12-19',
    contractor: '66968675225983176496f5d4',
    approvalStatus: 'Approved',
    supportingDocuments: [
      '1721274527736-QC_report_dummy',
      '1721274527736-Tender_for_Building_1_compressed',
    ],
    remarks:
      'Check if all the documents are clear and cut. Then proceed after taking the initials and passing the bill. ',
  });

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    // setFunctionaries(
    //   // On autofill we get a the stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );

    setData({ ...data, functionaries: value });

    console.log('Functionaries', value);
  };

  const fetchAllContractors = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/contractors-list`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    const data = await response.json();
    setContractors(data);
  };

  const fetchAllFunctionaries = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/functionaries-list`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    const data = await response.json();

    setInitialFunctionaries(data);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/create-project`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
        },
      );
      const res = await response.json();
      if (res.success) {
        console.log('Project created successfully');
      } else {
        console.log('Error creating project');
      }

      toast.success('Project created successfully');
      navigate('/management/projectstatus');
      navigate;
    } catch (error) {
      console.log('Error creating project', error);
    }
  };

  useEffect(() => {
    fetchAllContractors();
    fetchAllFunctionaries();
  }, []);

  return (
    <div>
      <Container
        maxWidth="lg"
        sx={{
          pt: 4,
          pb: 4,
        }}
      >
        <div className="flex items-start justify-between space-x-10">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Project Estimation Form" />
                <Divider />
                <CardContent className="flex flex-col space-y-5">
                  <div className="grid w-full grid-cols-2 gap-4 rounded-xl bg-gray-50 border p-5">
                    <h2 className="col-span-2 text-2xl font-semibold">
                      Project Details
                    </h2>
                    <TextField
                      required
                      label="Project Code"
                      value={data.projectCode}
                      onChange={(e) =>
                        setData({ ...data, projectCode: e.target.value })
                      }
                      fullWidth
                    />
                    <TextField
                      required
                      select
                      label="Main Category"
                      fullWidth
                      value={data.mainCategory}
                      onChange={(e) =>
                        setData({ ...data, mainCategory: e.target.value })
                      }
                    >
                      {mainCategories.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      select
                      label="Type of Work"
                      fullWidth
                      value={data.typeOfWork}
                      onChange={(e) =>
                        setData({ ...data, typeOfWork: e.target.value })
                      }
                    >
                      {typesOfWork
                        .filter((type) => type.id === data.mainCategory)[0]
                        .options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                      required
                      label="Work Name"
                      value={data.workName}
                      onChange={(e) =>
                        setData({ ...data, workName: e.target.value })
                      }
                      fullWidth
                    />

                    <TextField
                      required
                      select
                      label="Circle"
                      fullWidth
                      value={data.circle}
                      onChange={(e) =>
                        setData({ ...data, circle: e.target.value })
                      }
                    >
                      {circles.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      select
                      label="Ward"
                      fullWidth
                      value={data.ward}
                      onChange={(e) =>
                        setData({ ...data, ward: e.target.value })
                      }
                    >
                      {wards.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      select
                      label="Nature of Work"
                      fullWidth
                      value={data.natureOfWork}
                      onChange={(e) =>
                        setData({ ...data, natureOfWork: e.target.value })
                      }
                    >
                      {natureOfWorks.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      select
                      label="Department"
                      fullWidth
                      value={data.department}
                      onChange={(e) =>
                        setData({ ...data, department: e.target.value })
                      }
                    >
                      {departments.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label="Description"
                      multiline
                      rows={4}
                      fullWidth
                      className="col-span-2"
                      value={data.description}
                      onChange={(e) =>
                        setData({ ...data, description: e.target.value })
                      }
                    />
                  </div>
                  <div className="grid w-full grid-cols-2 gap-4 rounded-xl bg-gray-50 border p-5">
                    <h2 className="col-span-2 text-2xl font-semibold">
                      Financial Details
                    </h2>
                    <TextField
                      required
                      select
                      label="Fund Type"
                      fullWidth
                      value={data.fundType}
                      onChange={(e) =>
                        setData({ ...data, fundType: e.target.value })
                      }
                    >
                      {fundTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      label="Budget Head"
                      fullWidth
                      value={data.budgetHead}
                      onChange={(e) =>
                        setData({ ...data, budgetHead: e.target.value })
                      }
                    />

                    {/* <TextField
                      required
                      label="Functionaries"
                      
                      fullWidth
                    /> */}
                    <FormControl fullWidth>
                      <InputLabel htmlFor="demo-multiple-chip">
                        Functionaries
                      </InputLabel>

                      <Select
                        labelId="demo-multiple-chip"
                        label="Functionaries"
                        id="demo-multiple-chip"
                        multiple
                        value={data.functionaries}
                        onChange={handleChange}
                        sx={{
                          // selected items in the menu dropdown should have a green background
                          '& .Mui-selected': { backgroundColor: 'green' },
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {/* {selected.map((value) => (
                              <div className="bg-gray-200  p-2 inline-flex break-words rounded-xl">
                                {value}
                              </div>
                            ))} */}
                            {initialFunctionaries
                              .filter((name) =>
                                data.functionaries.includes(name._id),
                              )
                              .map((name) => (
                                <div className="bg-gray-200  p-2 inline-flex break-words rounded-xl">
                                  {name.name} - {name.role}
                                </div>
                              ))}
                          </Box>
                        )}
                      >
                        {initialFunctionaries.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.name} - {name.role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      required
                      select
                      label="Functions"
                      fullWidth
                      value={data.functions}
                      onChange={(e) =>
                        setData({ ...data, functions: e.target.value })
                      }
                    >
                      {functions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      select
                      label="Scheme"
                      fullWidth
                      value={data.scheme}
                      onChange={(e) =>
                        setData({ ...data, scheme: e.target.value })
                      }
                    >
                      {schemes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      select
                      label="Sub Scheme"
                      fullWidth
                      value={data.subScheme}
                      onChange={(e) =>
                        setData({ ...data, subScheme: e.target.value })
                      }
                    >
                      {subSchemes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="grid w-full grid-cols-2 gap-4 rounded-xl bg-gray-50 border p-5">
                    <h2 className="col-span-2 text-2xl font-semibold">
                      Estimation
                    </h2>
                    <TextField
                      required
                      label="Estimated Cost"
                      type="number"
                      fullWidth
                      inputProps={{ min: 0 }}
                      value={data.estimatedCost}
                      onChange={(e) =>
                        setData({ ...data, estimatedCost: e.target.value })
                      }
                    />

                    <TextField
                      required
                      label="Project Duration (Start Date)"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={data.projectDurationStart}
                      onChange={(e) =>
                        setData({
                          ...data,
                          projectDurationStart: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      label="Project Duration (End Date)"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={data.projectDurationEnd}
                      onChange={(e) =>
                        setData({ ...data, projectDurationEnd: e.target.value })
                      }
                    />

                    <TextField
                      required
                      select
                      label="Contractor"
                      fullWidth
                      value={data.contractor}
                      onChange={(e) =>
                        setData({ ...data, contractor: e.target.value })
                      }
                    >
                      {contractors.map((name) => (
                        <MenuItem key={name?._id} value={name?._id}>
                          {name?.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      select
                      label="Approval Status"
                      fullWidth
                      value={data.approvalStatus}
                      onChange={(e) =>
                        setData({ ...data, approvalStatus: e.target.value })
                      }
                    >
                      {approvalStatuses.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label="Supporting Documents"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ multiple: true }}
                    />

                    <TextField
                      label="Remarks"
                      multiline
                      rows={4}
                      fullWidth
                      className="col-span-2"
                      value={data.remarks}
                      onChange={(e) =>
                        setData({ ...data, remarks: e.target.value })
                      }
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div className="flex justify-end mt-5">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            startIcon={<Save />}
          >
            Create & Save Project
          </Button>
        </div>
      </Container>
    </div>
  );
}
