import Backdrop from '@mui/material/Backdrop';
import { Box, Modal, Typography } from '@mui/material';
import { Fade } from '@mui/material';
import { Button } from '@mui/material';
import { useState } from 'react';
import UploadImage from 'src/components/UploadImage';
import ExcelUpload from './ExcelUpload';
import StepperComp from './StepperComp';

export default function UploadModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={
          <svg
            className="icon icon-tabler icons-tabler-outline icon-tabler-world-upload"
            fill="none"
            height="24"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" fill="none" stroke="none" />
            <path d="M21 12a9 9 0 1 0 -9 9" />
            <path d="M3.6 9h16.8" />
            <path d="M3.6 15h8.4" />
            <path d="M11.578 3a17 17 0 0 0 0 18" />
            <path d="M12.5 3c1.719 2.755 2.5 5.876 2.5 9" />
            <path d="M18 21v-7m3 3l-3 -3l-3 3" />
          </svg>
        }
      >
        Upload Files & Images
      </Button>
      {/* @ts-ignore */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <div className="bg-white  relative w-full text-center  overflow-y-scroll text-gray-500 font-semibold text-base rounded min-h-screen  flex flex-col  cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]">
          <div
            onClick={handleClose}
            className="fixed text-red-500  rounded-xl border-2 hover:border-red-500 hover:bg-red-100 top-5 right-10 p-2"
          >
            Close X
          </div>
          <StepperComp handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
}
