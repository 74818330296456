import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import TasksAnalytics from './Analysis';
import TasksAnalytics2 from './Analysis2';
import Footer from 'src/components/Footer';

const Financials = () => {
  const theme = useTheme();

  return (
    <div>
      <Helmet>
        <title>
          Financials
        </title>
      </Helmet>
      <Container
        maxWidth="lg"
        sx={{ marginTop: 4 }}
      >
        <Card variant="outlined">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
          >
            <Box
              p={4}
              sx={{
                background: `${theme.colors.alpha.black[5]}`,
                width: 1,
              }}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <TasksAnalytics />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Card>

        <Card
          variant="outlined"
          sx={{
            marginTop: 4,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
          >
            <Box
              p={4}
              sx={{
                background: `${theme.colors.alpha.black[5]}`,
                width: 1,
              }}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <TasksAnalytics2 />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Card>

        <Card
          variant="outlined"
          sx={{
            marginTop: 4,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
          >
            <Box
              p={4}
              sx={{
                background: `${theme.colors.alpha.black[5]}`,
                width: 1,
              }}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Typography variant="h4">
                    Annual
                    Reports
                  </Typography>

                  <Grid
                    style={{
                      display:
                        'flex',
                      flexWrap:
                        'wrap',
                    }}
                    gap={3}
                    marginTop={
                      4
                    }
                  >
                    {Array(24)
                      .fill(0)
                      .map(
                        (
                          _,
                          i,
                        ) => {
                          const year =
                            2025 -
                            i;
                          return (
                            <Card
                              variant="outlined"
                              sx={{
                                maxWidth: 345,
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image="https://westoba.com/wp-content/uploads/2024/04/AnnualReport_Promo_SubpageBanner_1920x550.jpg"
                                alt="banner"
                              />
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {
                                    year
                                  }

                                  -
                                  {year +
                                    1}
                                </Typography>
                              </CardContent>
                            </Card>
                          );
                        },
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default Financials;
