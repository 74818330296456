import {
  Box,
  CardHeader,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Tooltip,
  styled,
  Chip,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import {
  CheckCircleTwoTone,
  CloseTwoTone,
  CropSquareTwoTone,
  LoopTwoTone,
} from '@mui/icons-material';
import { Fragment } from 'react';

const TimelineWrapper = styled(Timeline)(
  ({ theme }) => `
    margin-left: ${theme.spacing(2)};

    .MuiTimelineDot-root {
      left: -${theme.spacing(2)};
      margin-top: 0;
      top: ${theme.spacing(0.5)};
    }
    
    .MuiTimelineContent-root {
      padding-left: ${theme.spacing(4)};
    }
    
    .MuiFormControlLabel-root {
      margin-left: -${theme.spacing(0.7)};
    }
    
    .MuiFormControlLabel-label {
      color: ${theme.colors.alpha.black[50]};
    }
`,
);

const CheckboxWrapper = styled(Checkbox)(
  ({ theme }) => `
    padding: ${theme.spacing(0.5)};
`,
);

function Checklist({ project }) {
  return (
    <Box>
      <CardHeader
        sx={{
          px: 0,
          pt: 0,
        }}
        action={
          <Tooltip arrow title="Refresh list">
            <IconButton size="small" color="primary">
              <RefreshTwoToneIcon />
            </IconButton>
          </Tooltip>
        }
        title="Checklist"
      />

      <TimelineWrapper>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <AssignmentTwoToneIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant="h4"
              sx={{
                pb: 2,
              }}
            >
              Contractor Checklist
            </Typography>
            <FormGroup>
              <FormControlLabel
                checked
                control={<CheckboxWrapper color="primary" name="checkedC" />}
                label="Land Survey"
              />
              <FormControlLabel
                checked
                control={<CheckboxWrapper color="primary" name="checkedC" />}
                label="Building Permit"
              />
              <FormControlLabel
                checked
                control={<CheckboxWrapper color="primary" name="checkedC" />}
                label="Construction Contract"
              />
            </FormGroup>
          </TimelineContent>
        </TimelineItem>
        {project?.projectApprovalProgress?.map((approvalProgress, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                color={
                  approvalProgress?.status === 'Pending' ? 'primary' : 'success'
                }
              >
                {approvalProgress?.status === 'Pending' && (
                  <AssignmentTwoToneIcon />
                )}

                {approvalProgress?.status === 'Forward' && (
                  <CheckCircleTwoTone />
                )}
                {approvalProgress?.status === 'Revert' && <LoopTwoTone />}
                {approvalProgress?.status === 'Reject' && (
                  <CloseTwoTone color="error" />
                )}

                {approvalProgress?.status === 'Non Relevant' && (
                  <CropSquareTwoTone color="secondary" />
                )}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant="h4"
                sx={{
                  pb: 2,
                }}
              >
                {approvalProgress?.level}
              </Typography>
              <Fragment>
                {approvalProgress?.status === 'Pending' && (
                  <Chip
                    label="Pending"
                    color="warning"
                    variant="filled"
                    sx={{
                      m: 1,
                    }}
                  />
                )}

                {approvalProgress?.status === 'Forward' && (
                  <Chip
                    label="Forwarded"
                    color="success"
                    variant="filled"
                    sx={{
                      m: 1,
                    }}
                  />
                )}

                {approvalProgress?.status === 'Revert' && (
                  <Chip
                    label="Reverted"
                    color="info"
                    variant="filled"
                    sx={{
                      m: 1,
                    }}
                  />
                )}

                {approvalProgress?.status === 'Reject' && (
                  <Chip
                    label="Rejected"
                    color="error"
                    variant="filled"
                    sx={{
                      m: 1,
                    }}
                  />
                )}

                {approvalProgress?.status === 'Non Relevant' && (
                  <Chip
                    label="Non Relevant"
                    color="secondary"
                    variant="filled"
                    sx={{
                      m: 1,
                    }}
                  />
                )}
              </Fragment>
            </TimelineContent>
          </TimelineItem>
        ))}
      </TimelineWrapper>
    </Box>
  );
}

export default Checklist;
