import {
  FC,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Footer from 'src/components/Main/Footer';
import Header from 'src/components/Main/Header';
import HeaderTop from 'src/components/Main/HeaderTop';
interface HomeLayoutProps {
  children?: ReactNode;
}

const HomeLayout: FC<
  HomeLayoutProps
> = ({ children }) => {
  return (
    <div className="flex  bg-white  items-center w-screen flex-col ">
      <HeaderTop />
      <Header />
      {children || <Outlet />}
      <Footer />
    </div>
  );
};

HomeLayout.propTypes = {
  children: PropTypes.node,
};

export default HomeLayout;
