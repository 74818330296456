import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { styled, Tab, Tabs } from '@mui/material';
import UploadModal from 'src/content/pages/Components/Forms/UploadModal';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
      padding: 0 ${theme.spacing(2)};
      position: relative;
      bottom: -1px;

      .MuiTabs-root {
        height: 44px;
        min-height: 44px;
      }

      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          min-height: 4px;
          height: 4px;
          box-shadow: none;
          bottom: -4px;
          background: none;
          border: 0;

          &:after {
            position: absolute;
            left: 50%;
            width: 28px;
            content: ' ';
            margin-left: -14px;
            background: ${theme.colors.primary.main};
            border-radius: inherit;
            height: 100%;
          }
      }

      .MuiTab-root {
          &.MuiButtonBase-root {
              height: 44px;
              min-height: 44px;
              background: ${theme.colors.alpha.white[50]};
              border: 1px solid ${theme.colors.alpha.black[10]};
              border-bottom: 0;
              position: relative;
              margin-right: ${theme.spacing(1)};
              font-size: ${theme.typography.pxToRem(14)};
              color: ${theme.colors.alpha.black[80]};
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              .MuiTouchRipple-root {
                opacity: .1;
              }

              &:after {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                bottom: 0;
                height: 1px;
                content: '';
                background: ${theme.colors.alpha.black[10]};
              }

              &:hover {
                color: ${theme.colors.alpha.black[100]};
              }
          }

          &.Mui-selected {
              color: ${theme.colors.alpha.black[100]};
              background: ${theme.colors.alpha.white[100]};
              border-bottom-color: ${theme.colors.alpha.white[100]};

              &:after {
                height: 0;
              }
          }
      }
  `,
);
export default function ProjectDrawer({ children, intent }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [currentTab, setCurrentTab] = React.useState<string>('employee');
  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    value: string,
  ): void => {
    setCurrentTab(value);
  };

  const tabs = [
    {
      value: 'employee',
      label: 'Employee',
    },
    {
      value: 'contractor',
      label: 'Contractor',
    },
  ];

  return (
    <div>
      <button
        className="text-red-500  w-full text-left"
        onClick={toggleDrawer('right', true)}
      >
        {children}
      </button>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        sx={{
          '& .MuiDrawer-paper': { width: 600 },
        }}
      >
        <div key="1" className="p-10">
          <TabsContainerWrapper>
            <div className="flex items-center space-x-2">
              <Tabs
                onChange={handleTabsChange}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <UploadModal />
            </div>
          </TabsContainerWrapper>
          {currentTab === 'employee' && (
            <div className="p-2">
              <div className="ps-2 my-2 pt-5 first:mt-0">
                <h3 className="text-xs font-medium uppercase text-gray-500">
                  10 Jul, 2024
                </h3>
              </div>
              <div className="flex gap-x-3 relative group rounded-lg hover:bg-gray-100">
                <a href="#" className="absolute inset-0 z-[1]' href=" />
                <div className="relative last:after:hidden after:absolute after:top-0 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-white border-2 border-gray-300 group-hover:border-gray-600" />
                  </div>
                </div>
                <div className="grow p-2 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    <svg
                      className="flex-shrink-0 size-4 mt-1"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                      <polyline points="14 2 14 8 20 8" />
                      <line x1="16" x2="8" y1="13" y2="13" />
                      <line x1="16" x2="8" y1="17" y2="17" />
                      <line x1="10" x2="8" y1="9" y2="9" />
                    </svg>
                    Land Survey & Planning
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    The land survey and planning has been completed.
                  </p>
                  <button
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    type="button"
                  >
                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                      A
                    </span>
                    Aditya S - JE1BGM
                  </button>
                  <div className="md:max-w-2xl md:mx-auto">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {Array.from({ length: 3 }).map((_, i) => (
                        <img
                          alt="Image Description"
                          className="w-full sm:size-40 rounded-xl object-cover"
                          src={`/static/projects/temp/${i + 1}.jpg`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-x-3 relative group rounded-lg hover:bg-gray-100">
                <a href="#" className="absolute inset-0 z-[1]' href=" />
                <div className="relative last:after:hidden after:absolute after:top-0 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-white border-2 border-gray-300 group-hover:border-gray-600" />
                  </div>
                </div>
                <div className="grow p-2 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    Foundation & Structure Review
                  </h3>
                  <button
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    type="button"
                  >
                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                      A
                    </span>
                    Abhijit M - AEE BGM
                  </button>
                  <div className="md:max-w-2xl md:mx-auto">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {Array.from({ length: 6 }).map((_, i) => (
                        <img
                          alt="Image Description"
                          className="w-full sm:size-40 rounded-xl object-cover"
                          src={`/static/projects/temp/${i + 3}.jpg`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-x-3 relative group rounded-lg hover:bg-gray-100">
                <a href="#" className="absolute inset-0 z-[1]' href=" />
                <div className="relative last:after:hidden after:absolute after:top-0 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-white border-2 border-gray-300 group-hover:border-gray-600" />
                  </div>
                </div>
                <div className="grow p-2 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    Material Inspection & Approval
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Material inspection and approval has been completed.
                  </p>
                  <button
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    type="button"
                  >
                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                      A
                    </span>
                    Aditya S - JE1BGM
                  </button>
                  <div className="md:max-w-2xl md:mx-auto">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {Array.from({ length: 3 }).map((_, i) => (
                        <img
                          alt="Image Description"
                          className="w-full sm:size-40 rounded-xl object-cover"
                          src={`/static/projects/temp/${i + 9}.jpg`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="ps-2 my-2 first:mt-0">
                <h3 className="text-xs font-medium uppercase text-gray-500">
                  15 Jul, 2024
                </h3>
              </div>
              <div className="flex gap-x-3 relative group rounded-lg hover:bg-gray-100">
                <a href="#" className="absolute inset-0 z-[1]' href=" />
                <div className="relative last:after:hidden after:absolute after:top-0 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-white border-2 border-gray-300 group-hover:border-gray-600" />
                  </div>
                </div>
                <div className="grow p-2 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    Regular Maintenance & Progress Check
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Regular maintenance and progress check has been completed.
                    The project is on track.
                  </p>
                </div>
              </div>
            </div>
          )}

          {currentTab === 'contractor' && (
            <div className="p-2">
              <div className="ps-2 my-2 pt-5 first:mt-0">
                <h3 className="text-xs font-medium uppercase text-gray-500">
                  1 Jul, 2024
                </h3>
              </div>
              <div className="flex gap-x-3 relative group rounded-lg hover:bg-gray-100">
                <a href="#" className="absolute inset-0 z-[1]' href=" />
                <div className="relative last:after:hidden after:absolute after:top-0 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-white border-2 border-gray-300 group-hover:border-gray-600" />
                  </div>
                </div>
                <div className="grow p-2 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    <svg
                      className="flex-shrink-0 size-4 mt-1"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                      <polyline points="14 2 14 8 20 8" />
                      <line x1="16" x2="8" y1="13" y2="13" />
                      <line x1="16" x2="8" y1="17" y2="17" />
                      <line x1="10" x2="8" y1="9" y2="9" />
                    </svg>
                    Uploaded Relevant Documents
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    We have uploaded all the tender documents and the project
                    plan. Please review and approve.
                  </p>
                  <button
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    type="button"
                  >
                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                      PG
                    </span>
                    PG Shetty Construction Technology Pvt Ltd
                  </button>
                  <div className="md:max-w-2xl md:mx-auto">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {Array.from({ length: 3 }).map((_, i) => (
                        <img
                          alt="Image Description"
                          className="w-full sm:size-40 rounded-xl object-cover"
                          src={`/static/docs/${i + 1}.jpg`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-x-3 relative group rounded-lg hover:bg-gray-100">
                <a href="#" className="absolute inset-0 z-[1]' href=" />

                <div className="relative last:after:hidden after:absolute after:top-0 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                  <div className="relative z-10 size-7 flex justify-center items-center">
                    <div className="size-2 rounded-full bg-white border-2 border-gray-300 group-hover:border-gray-600" />
                  </div>
                </div>
                <h3 className="text-xs font-medium uppercase text-gray-500">
                  18 Jul, 2024
                </h3>
                <div className="grow p-2 pb-8">
                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                    Bills & Invoices
                  </h3>
                  <button
                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    type="button"
                  >
                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                      PG
                    </span>
                    PG Shetty Construction Technology Pvt Ltd
                  </button>
                  <div className="md:max-w-2xl md:mx-auto">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {Array.from({ length: 6 }).map((_, i) => (
                        <img
                          alt="Image Description"
                          className="w-full sm:size-40 rounded-xl object-cover"
                          src={`/static/docs/${i + 3}.jpg`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}
