export const projectData = [
  {
    id: 1,
    image:
      '/static/projects/pro1/Superstructure concrete for SF 1st half.jpg',
    images: [
      '/static/projects/pro1/Superstructure concrete for SF 1st half.jpg',
      '/static/projects/pro1/Superstructure concrete for SF 2nd half.jpg',
      '/static/projects/pro1/sump tank roof concrete.jpg',
    ],
    name: 'KERAGODU 12 PC QTRS - PG 2020 - PKG 10 - 2013-14',
    ContractorName:
      'PG Shetty Construction Technology Pvt Ltd',
    ProjectManager:
      'AE1MANDYA',
    EstimateCostInRs: 19282000.0,
    AgreementNumber:
      'PHC/Contracts/PG-2020/LS/2014-15/12, Dt. 22.05.2014',
    ProjectCode: '7254',
    ScheduledStartDate:
      '20 Aug 2014',
    ScheduledEndDate:
      '19 Oct 2015',
    ActualStartDate:
      '20 Aug 2014',
    ActualEndDate:
      '11 Feb 2016',
    AmountPutToTender: 15953000.0,
    ContractAmountInRs: 16756414.0,
    ProjectStatus:
      'Completed',
    ContractPeriod:
      '1 year 2 months 0 days',
    DistrictName: 'MANDYA',
    PlinthAreaSqm: 268.77,
    StatusMilestone:
      'Completion of Handing over to police',
    ActualExecutionPeriod:
      '1 year 5 months 25 days',
    UptoDateExpenditure: 25302184.0,
  },
  {
    id: 2,
    image:
      '/static/projects/pro2/24.jpeg',
    images: [
      '/static/projects/pro1/Superstructure concrete for SF 1st half.jpg',
    ],
    name: 'AIGALI POLICE STATION (GRADE 4) IN BELAGAVI DISTRICT UNDER PACKAGE-03',
    ContractorName:
      'M/s Sree Builders',
    ProjectManager: 'JE1BGM',
    EstimateCostInRs: 17600000.0,
    AgreementNumber:
      'PHC/CONTRACTS/IRA/2021-22/36 DATED : 03.09.2021',
    ProjectCode:
      'PHC/191/BGM/31/2021/H',
    ScheduledStartDate:
      '01 Oct 2021',
    ScheduledEndDate:
      '27 Aug 2022',
    ActualStartDate:
      '01 Oct 2021',
    ActualEndDate: 'Ongoing',
    AmountPutToTender: 13082360.16,
    ContractAmountInRs: 13583316.72,
    ProjectStatus: 'Ongoing',
    ContractPeriod:
      '0 year 11 months 0 days',
    DistrictName: 'BELGAUM',
    PlinthAreaSqm: 313.55,
    StatusMilestone:
      'Completion of all works',
    ActualExecutionPeriod:
      'Ongoing',
    UptoDateExpenditure: 5403198.82,
  },

  {
    id: 4,
    image:
      '/static/projects/pro4/Alanahalli Police Station work completed 2.jpeg',
    images: [
      '/static/projects/pro1/Superstructure concrete for SF 1st half.jpg',
    ],
    name: 'ALANAHALLI- HEBBALA- DEVARAJA POLICE STATION AND DEVARAJA TRAFFIC POLICE STATION IN MYSORE CITY PKG-17',
    ContractorName:
      'H A Vinod Kumar',
    ProjectManager: 'AE2MYS',
    EstimateCostInRs: 5131317.0,
    AgreementNumber:
      'PHC/contracts/IRA/2021-22/71-22.12.2021',
    ProjectCode: '568A',
    ScheduledStartDate:
      '03 Jan 2022',
    ScheduledEndDate:
      '29 Nov 2022',
    ActualStartDate:
      '03 Jan 2022',
    ActualEndDate: 'Ongoing',
    AmountPutToTender: 49220014.39,
    ContractAmountInRs: 49220014.0,
    ProjectStatus: 'Ongoing',
    ContractPeriod:
      '0 year 11 months 0 days',
    DistrictName: 'MYSORE',
    PlinthAreaSqm: 3500.0,
    StatusMilestone:
      'Completion of handing over of land to the agency',
    ActualExecutionPeriod:
      'Ongoing',
    UptoDateExpenditure: 35719246.7,
  },
  {
    id: 3,
    image:
      '/static/projects/pro3/Flood light2.jpeg',
    images: [
      '/static/projects/pro1/Superstructure concrete for SF 1st half.jpg',
    ],
    name: 'CHITRADURGA BASAVESHWARA NAGARA COMPOUND WALL ROAD AND DRAIN FOR 48 PC AND 12 SI QTRS UNDER PG-2020 DEVELOPMENTAL WORKS PACKAGE-58',
    ContractorName:
      'M/s M Babu & Co',
    ProjectManager: 'AE2SMG',
    EstimateCostInRs: 21690000.0,
    AgreementNumber:
      'PHC/Contracts/IR/A2020-21/76 Dt: 05.02.2021',
    ProjectCode:
      'PHC/DVG/17/3/PG-2020/92',
    ScheduledStartDate:
      '08 Feb 2021',
    ScheduledEndDate:
      '09 May 2021',
    ActualStartDate:
      '08 Feb 2021',
    ActualEndDate:
      '23 Mar 2021',
    AmountPutToTender: 16683706.0,
    ContractAmountInRs: 15632132.44,
    ProjectStatus:
      'Completed',
    ContractPeriod:
      '0 year 3 months 0 days',
    DistrictName:
      'CHITRADURGA',
    PlinthAreaSqm: 0.0,
    StatusMilestone:
      'Handed over to user department',
    ActualExecutionPeriod:
      '0 year 1 months 13 days',
    UptoDateExpenditure: 17429115.09,
  },
  {
    id: 5,
    image:
      '/static/projects/pro5/Building front view -1.jpg',
    images: [
      '/static/projects/pro1/Superstructure concrete for SF 1st half.jpg',
    ],
    name: 'MANGALORE TRAFFIC POLICE STATION (NORTH) PKG-32',
    ContractorName:
      'M/s K R Janardhan Babu Constructions',
    ProjectManager: 'AE2MNG',
    EstimateCostInRs: 20000000.0,
    AgreementNumber:
      'PHC/Contracts/IR/A2021-22/89 dtd: 30.12.2021',
    ProjectCode: '386',
    ScheduledStartDate:
      '03 Feb 2022',
    ScheduledEndDate:
      '03 Oct 2022',
    ActualStartDate:
      '03 Feb 2022',
    ActualEndDate:
      '31 Jan 2023',
    AmountPutToTender: 15763041.9,
    ContractAmountInRs: 15144385.7,
    ProjectStatus:
      'Completed',
    ContractPeriod:
      '0 year 8 months 2 days',
    DistrictName:
      'DAKSHINA KANNADA',
    PlinthAreaSqm: 0.0,
    StatusMilestone: '301',
    ActualExecutionPeriod:
      '0 year 12 months 2 days',
    UptoDateExpenditure: 12631680.83,
  },
];
