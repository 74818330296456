import CountUp from 'react-countup';
import { Info } from 'src/components/Icons';

export default function Projects() {
  return (
    <div className="max-w-7xl mx-auto">
      {/* // title of the page */}
      {/* STATS */}

      <div
        key="1"
        className="relative bg-gradient-to-bl from-secondary via-transparent rounded-xl "
      >
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid items-center md:grid-cols-2 gap-8 lg:gap-12">
            <div>
              <div className="text-sm  pb-10 sm:text-xl flex items-center text-gray-600 ">
                <Info />

                <span className="text-sm">
                  Click on the
                  map to view
                  projects in
                  each
                  district
                </span>
              </div>
              <p>
                <img
                  alt="District Map of Karnataka"
                  height="450"
                  src="/map.jpg"
                  useMap="#h3s1502"
                  width="328"
                  className="mix-blend-multiply"
                />
                <br />
                <br />
                <br />
                <map name="h3s1502">
                  <area
                    alt="District of Bidar"
                    coords="223, 52, 214, 47, 209, 47, 207, 44, 198, 42, 195, 46, 186, 46, 181, 42, 185, 37, 185, 32, 193, 32, 193, 25, 193, 19, 203, 14, 209, 10, 211, 4, 218, 0, 222, 2, 221, 8, 231, 10, 233, 20, 230, 24, 238, 28, 228, 37, 231, 42, 226, 44"
                    href="district-page?district=bidar"
                  />
                  <area
                    alt="District of Udupi"
                    coords="47, 258, 42, 258, 45, 276, 51, 291, 51, 297, 53, 314, 73, 310, 75, 307, 73, 297, 73, 292, 70, 287, 68, 272, 60, 261"
                    href="district-page?district=udupi"
                  />
                  <area
                    alt="District of Hassan"
                    coords="150, 283, 155, 288, 149, 294, 154, 304, 167, 313, 168, 318, 159, 328, 150, 324, 152, 340, 144, 337, 137, 342, 126, 340, 126, 324, 119, 333, 108, 333, 101, 319, 114, 312, 116, 302, 129, 302, 129, 296, 145, 285, 143, 285, 154, 285, 154, 287"
                    href="district-page?district=hassan"
                    shape="POLY"
                  />
                  <area
                    alt="District of Mandya"
                    coords="147, 342, 148, 326, 159, 330, 167, 317, 174, 317, 184, 329, 192, 329, 197, 345, 202, 347, 207, 357, 196, 362, 188, 362, 183, 352, 172, 357, 167, 352, 161, 354"
                    href="district-page?district=mandya"
                    shape="POLY"
                  />
                  <area
                    alt="District of Bangalore Rural"
                    coords="218, 303, 218, 299, 213, 301, 213, 303, 212, 293, 218, 298, 209, 298, 214, 297, 212, 289, 218, 288, 232, 294, 231, 292, 246, 306, 241, 299, 246, 310, 246, 303, 243, 308, 244, 306, 248, 307, 245, 314, 248, 309, 248, 317, 244, 311, 246, 313, 243, 313, 244, 312, 246, 316, 245, 317, 244, 312, 241, 316, 238, 317, 233, 320, 229, 313, 224, 308, 226, 312, 216, 305, 215, 297, 219, 306, 221, 303, 217, 303, 215, 301, 215, 300, 214, 304"
                    href="district-page?district=rural"
                    shape="POLY"
                  />{' '}
                  <area
                    alt="District of Bangalore Urban"
                    coords="230, 344, 205, 350, 220, 348, 201, 353, 199, 348, 202, 353, 197, 350, 199, 344, 202, 338, 205, 333, 214, 330, 240, 323, 229, 346, 223, 349, 222, 349, 211, 349, 203, 351"
                    href="district-page?district=urban"
                    shape="POLY"
                  />
                  <area
                    alt="District of Bangalore Urban 2"
                    coords="200, 334, 228, 331, 229, 329, 199, 333, 231, 327, 225, 328, 230, 332, 226, 329, 230, 333, 231, 332, 225, 330, 226, 329, 227, 327, 223, 326, 228, 326, 231, 328, 231, 332, 229, 331, 216, 299, 229, 326, 230, 329, 226, 333, 228, 331, 225, 329, 227, 324, 228, 334, 219, 334, 229, 330, 227, 328, 223, 328, 228, 333, 230, 333, 227, 320, 220, 333, 232, 326, 223, 329, 229, 332, 230, 333, 224, 328, 226, 331, 230, 328, 231, 331, 216, 297, 196, 303"
                    href="district-page?district=urban2"
                    shape="POLY"
                  />
                  <area
                    alt="District of Ramanagar"
                    coords="238, 372, 236, 370, 232, 377, 227, 378, 222, 378, 219, 375, 218, 369, 213, 363, 210, 364, 211, 358, 205, 356, 206, 349, 208, 348, 217, 348, 196"
                    href="district-page?district=ramanagar"
                    shape="POLY"
                  />
                  <area
                    alt="District of Kolar"
                    coords="243, 298, 248, 297, 254, 298, 257, 297, 262, 299, 264, 293, 267, 290, 269, 284, 272, 280, 280, 279, 280, 283, 279, 286, 280, 291, 279, 293, 278, 294, 283, 297, 287, 300, 286, 294, 289, 300, 290, 308, 280, 305, 284, 316, 283, 320, 281, 323, 277, 322, 276, 325, 272, 327, 271, 330, 267, 332, 263, 329, 260, 327, 258, 326, 258, 328, 256, 330, 252, 327, 250, 325, 247, 327, 245, 323, 247, 318, 251, 310, 253, 305, 250, 303, 247, 302, 242, 300"
                    href="district-page?district=kolar"
                  />
                  <area
                    alt="District of Chikkaballapur"
                    coords="218, 274, 222, 273, 229, 271, 234, 274, 235, 269, 239, 266, 239, 262, 240, 262, 242, 262, 243, 264, 247, 262, 249, 262, 248, 264, 247, 267, 251, 266, 254, 265, 257, 267, 258, 270, 257, 275, 259, 279, 262, 282, 262, 287, 261, 291, 259, 294, 258, 296, 257, 297, 257, 300, 252, 300, 249, 300, 246, 299, 241, 298, 237, 299, 233, 296, 232"
                    href="district-page?district=chikkaballapur"
                    shape="POLY"
                  />
                  <area
                    alt="District of Bagalkot"
                    coords="123, 147, 101, 146, 102, 130, 93, 126, 89, 120, 78, 114, 79, 111, 78, 103, 85, 96, 92, 98, 100, 88, 104, 109, 134, 115, 145, 128, 154, 143, 143, 143, 140, 138, 133, 142, 125, 137"
                    href="district-page?district=bagalkot"
                    shape="POLY"
                  />
                  <area
                    alt="District of Gadag"
                    coords="115, 199, 111, 198, 107, 191, 101, 191, 101, 177, 106, 171, 105, 169, 110, 161, 108, 154, 98, 156, 89, 154, 103, 145, 123, 146, 131, 146, 135, 151, 131, 151, 125, 163, 125, 169, 127, 185"
                    href="district-page?district=gadag"
                  />
                  <area
                    alt="District of Mysore"
                    coords="146, 392, 135, 385, 132, 379, 135, 371, 126, 359, 118, 353, 121, 344, 135, 344, 143, 341, 160, 353, 163, 353, 171, 358, 179, 354, 188, 361, 193, 364, 193, 368, 181, 368, 179, 376, 167, 376, 156, 382"
                    href="district-page?district=mysore"
                    shape="POLY"
                  />
                  <area
                    alt="District of Dakshin Kannad"
                    coords="52, 318, 54, 337, 63, 333, 68, 339, 78, 346, 86, 352, 90, 349, 95, 351, 102, 350, 105, 341, 98, 324, 93, 313, 86, 309, 76, 312"
                    href="district-page?district=dakshin"
                    shape="POLY"
                  />
                  <area
                    alt="District of Uttar Kannad"
                    coords="59, 172, 69, 180, 64, 186, 66, 193, 76, 193, 76, 203, 75, 217, 74, 222, 68, 225, 67, 230, 69, 239, 58, 245, 49, 247, 51, 257, 44, 260, 38, 235, 32, 218, 25, 213, 21, 202, 29, 199, 32, 190, 32, 179, 34, 177, 32, 165, 47, 175"
                    href="district-page?district=uttar"
                    shape="POLY"
                  />
                  <area
                    alt="District of Gulbarga"
                    coords="175, 35, 184, 40, 191, 41, 196, 37, 202, 38, 214, 42, 221, 45, 229, 47, 234, 51, 228, 55, 226, 59, 222, 58, 221, 63, 218, 66, 217, 66, 217, 69, 222, 76, 225, 80, 221, 83, 210, 88, 205, 89, 177, 94, 168, 95, 160, 95, 161, 83, 157, 67, 149, 67, 140, 58, 143, 57, 147, 59, 153, 56, 158, 58, 159, 54, 157, 43, 162, 40, 171, 33, 174, 33"
                    href="district-page?district=gulbarga"
                    shape="POLY"
                  />
                  <area
                    alt="District of Yadgir"
                    coords="162, 96, 168, 95, 171, 97, 179, 95, 184, 92, 188, 90, 193, 88, 196, 87, 203, 89, 209, 91, 214, 89, 218, 87, 223, 84, 223, 88, 224, 93, 223, 95, 223, 97, 221, 101, 223, 103, 224, 105, 221, 107, 219, 110, 216, 112, 215, 113, 211, 113, 209, 116, 204, 114, 199, 111, 197, 110, 194, 107, 189, 110, 184, 112, 180, 113, 174, 117, 169, 119, 163, 123, 157, 124, 155, 124, 153, 120, 156, 114, 160, 112, 160, 106, 159, 100, 161, 98, 162, 96"
                    href="district-page?district=yadgir"
                    shape="POLY"
                  />
                  <area
                    alt="District of Davangere"
                    coords="124, 269, 137, 260, 134, 250, 142, 232, 156, 230, 164, 221, 146, 214, 139, 200, 125, 203, 117, 211, 117, 225, 109, 237, 103, 237, 99, 248, 118, 251"
                    href="district-page?district=davangere"
                    shape="POLY"
                  />
                  <area
                    alt="District of Shimoga"
                    coords="76, 288, 83, 280, 92, 280, 100, 269, 123, 267, 120, 249, 107, 251, 96, 248, 102, 239, 97, 232, 92, 221, 77, 216, 67, 222, 66, 238, 56, 241, 50, 242, 50, 255, 63, 257, 70, 273"
                    href="district-page?district=shimoga"
                    shape="POLY"
                  />
                  <area
                    alt="District of Bijapur"
                    coords="138, 56, 134, 56, 131, 50, 125, 53, 118, 46, 112, 50, 115, 62, 115, 75, 109, 78, 101, 76, 95, 78, 97, 87, 101, 89, 102, 107, 119, 112, 133, 114, 141, 127, 155, 121, 151, 115, 157, 110, 159, 96, 159, 79, 157, 66, 145, 65, 133, 55, 132, 50, 127, 54"
                    href="district-page?district=bijapur"
                    shape="POLY"
                  />
                  <area
                    alt="District of ChitraDurga"
                    coords="174, 197, 181, 200, 186, 205, 180, 216, 180, 223, 188, 232, 188, 238, 189, 246, 189, 253, 185, 256, 177, 265, 177, 271, 168, 273, 164, 280, 152, 286, 147, 286, 142, 278, 132, 265, 138, 263, 134, 254, 141, 235, 153, 234, 163, 224, 175, 213"
                    href="district-page?district=chitradurga"
                    shape="POLY"
                  />
                  <area
                    alt="District of Tumkur"
                    coords="217, 276, 212, 282, 213, 291, 210, 300, 205, 300, 193, 313, 197, 320, 197, 334, 185, 332, 178, 319, 168, 319, 155, 309, 150, 299, 154, 295, 150, 286, 165, 280, 166, 271, 177, 270, 177, 266, 185, 254, 195, 261, 191, 273, 203, 274, 203, 267, 215, 268"
                    href="district-page?district=tumkur"
                    shape="POLY"
                  />
                  <area
                    alt="District of Chikmagalur"
                    coords="77, 309, 86, 305, 95, 310, 100, 321, 112, 313, 112, 304, 128, 302, 128, 298, 146, 285, 151, 285, 131, 265, 123, 269, 99, 269, 91, 279, 81, 281, 76, 290"
                    href="district-page?district=chikmagalur"
                    shape="POLY"
                  />
                  <area
                    alt="District of Koppal"
                    coords="149, 143, 138, 143, 136, 137, 131, 142, 120, 137, 119, 142, 133, 150, 131, 155, 127, 152, 120, 164, 123, 170, 123, 186, 131, 186, 137, 182, 179, 167, 163, 152"
                    href="district-page?district=koppal"
                    shape="POLY"
                  />
                  <area
                    alt="District of Haveri"
                    coords="76, 219, 76, 197, 82, 189, 100, 190, 109, 195, 117, 203, 116, 213, 119, 218, 119, 225, 107, 241, 99, 236, 92, 225"
                    href="district-page?district=haveri"
                    shape="POLY"
                  />
                  <area
                    alt="District of Raichur"
                    coords="148, 133, 156, 144, 160, 146, 166, 155, 184, 167, 191, 154, 199, 148, 215, 144, 225, 147, 228, 137, 225, 126, 232, 126, 223, 120, 211, 114, 196, 107"
                    href="district-page?district=raichur"
                    shape="POLY"
                  />
                  <area
                    alt="District of Bellary"
                    coords="181, 168, 165, 174, 148, 179, 142, 180, 137, 185, 129, 185, 116, 198, 114, 214, 121, 212, 121, 204, 139, 201, 143, 207, 146, 214, 162, 221, 176, 208, 175, 195, 180, 192, 196, 198, 203, 191, 198, 178, 203, 174, 194, 168, 199, 161, 200, 147, 189, 150"
                    href="district-page?district=bellary"
                    shape="POLY"
                  />
                  <area
                    alt="District of Dharwad"
                    coords="79, 194, 70, 192, 68, 184, 74, 179, 60, 169, 71, 158, 89, 154, 109, 156, 112, 161, 107, 174, 105, 191, 99, 191, 98, 187, 94, 189, 85, 185"
                    href="district-page?district=dharwad"
                    shape="POLY"
                  />
                  <area
                    alt="District of Chamarajnagar"
                    coords="185, 370, 183, 373, 171, 374, 170, 379, 161, 380, 148, 383, 138, 394, 145, 399, 153, 406, 166, 406, 172, 397, 185, 397, 205, 396, 209, 386, 217, 385, 224, 380, 220, 371, 208, 369, 196, 363"
                    href="district-page?district=chamarajnagar"
                    shape="POLY"
                  />
                  <area
                    alt="District of Belgaum"
                    coords="100, 83, 104, 90, 98, 97, 81, 102, 81, 114, 95, 124, 104, 129, 105, 147, 98, 151, 81, 157, 70, 157, 63, 167, 56, 173, 47, 172, 36, 165, 32, 158, 27, 157, 34, 147, 42, 148, 42, 143, 48, 137, 42, 133, 50, 130, 50, 121, 42, 117, 42, 111, 38, 103, 53, 97, 56, 100, 63, 100, 63, 91, 74, 91, 78, 81, 86, 80, 95, 84"
                    href="district-page?district=belgaum"
                    shape="POLY"
                  />
                  <area
                    alt="District of Kodagu"
                    coords="90, 348, 97, 357, 103, 365, 114, 371, 120, 378, 133, 378, 138, 369, 129, 358, 122, 350, 128, 342, 128, 326, 123, 333, 111, 333, 108, 343, 98, 343"
                    href="district-page?district=kodagu"
                    shape="POLY"
                  />
                </map>
              </p>
            </div>
            <div
              key="1"
              className=" px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
            >
              <div className="grid gap-6 grid-cols-1 sm:gap-12 lg:grid-cols-1 lg:gap-8">
                <div>
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-800 ">
                    Projects
                    completed
                  </h4>
                  <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">
                    <CountUp
                      end={
                        2000
                      }
                    />{' '}
                    +
                  </p>
                  <p className="mt-1 text-gray-500 ">
                    Initially:
                    136
                    Projects
                  </p>
                </div>
                <div>
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-800 ">
                    Average
                    Annual
                    Income
                  </h4>
                  <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">
                    INR{' '}
                    <CountUp
                      end={
                        33.79
                      }
                    />{' '}
                    Million
                  </p>
                  <p className="mt-1 text-gray-500 ">
                    Before:
                    INR 1.40
                    Million
                  </p>
                </div>
                <div>
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-800 ">
                    Average
                    Work
                    Done/Year
                  </h4>
                  <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">
                    INR{' '}
                    <CountUp
                      end={
                        875.03
                      }
                    />{' '}
                    Million
                  </p>
                  <p className="mt-1 text-gray-500 ">
                    Before:
                    INR 74.02
                    Million
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 md:mt-12 py-3 flex items-center text-sm text-gray-800 gap-x-1.5 after:flex-1 after:border-t after:border-gray-200 after:ms-6 ">
            <span className="font-semibold bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent ">
              It's trust that
            </span>
            <span>
              drives us to
              deliver
            </span>
          </div>
        </div>
      </div>

      {/* Project Portfolio */}

      <div
        key="1"
        className="max-w-[85rem] px-4 py-10  lg:py-14 mx-auto"
      >
        <div className="md:grid md:grid-cols-2 md:items-start md:gap-12 xl:gap-32">
          <div>
            <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent ">
              Building
              Excellence,
              Ensuring
              Transparency
            </p>
            <div className="mt-4 md:mb-12 max-w-2xl">
              <h1 className="mb-4 font-semibold text-gray-800 text-4xl lg:text-5xl ">
                Projects
                undertaken by
                KSPH&IDCL
              </h1>
              <p className="text-gray-600 ">
                Explore
                KSPH&IDCL's
                Projects Page
                to see our
                commitment to
                quality and
                transparency
                in action. Our
                innovative
                Web-based
                Project
                Management
                System (WBPMS)
                enables
                real-time
                monitoring and
                efficient use
                of public
                funds. From
                construction
                of essential
                public service
                buildings to
                ensuring
                stakeholder
                engagement,
                our projects
                reflect
                excellence,
                sustainability,
                and
                accountability.
                Witness our
                transformation
                journey and
                the superior
                outcomes we
                deliver.
              </p>
            </div>
            <blockquote className="hidden md:block relative max-w-sm">
              <svg
                aria-hidden="true"
                className="absolute top-0 start-0 transform -translate-x-6 -translate-y-8 size-16 text-gray-200 "
                fill="none"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                  fill="#5a58f3"
                />
              </svg>
              <div className="relative z-10">
                <p className="text-xl italic text-gray-800 ">
                  Over{' '}
                  <strong className="text-tertiary ">
                    2000
                  </strong>{' '}
                  Projects
                  Monitored
                  Real-Time
                  via WBPMS
                </p>
              </div>
            </blockquote>
          </div>
          <div className="mt-5  lg:mt-0">
            <div className="space-y-6 sm:space-y-8">
              <div className="space-y-2 md:space-y-4">
                <h2 className="font-bold text-3xl lg:text-4xl text-gray-800 ">
                  Continuous
                  Improvement
                  and
                  Innovation
                </h2>
                <p className="text-gray-500 ">
                  At
                  KSPH&IDCL,
                  we
                  prioritize
                  continuous
                  improvement
                  and
                  innovation.
                  Our
                  Web-based
                  Project
                  Management
                  System
                  (WBPMS)
                  exemplifies
                  this,
                  enabling
                  real-time
                  project
                  monitoring
                  and
                  stakeholder
                  engagement.
                  By embracing
                  new
                  technologies
                  and best
                  practices,
                  we ensure
                  every
                  project
                  meets the
                  highest
                  standards of
                  excellence,
                  sustainability,
                  and
                  transparency.
                </p>
              </div>
              <ul className="space-y-2 sm:space-y-4">
                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg
                      className="flex-shrink-0 size-3.5"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </span>
                  <span className="text-sm sm:text-base text-gray-500 ">
                    Projects
                    Currently
                    Monitored:
                    Over 2000
                    projects
                  </span>
                </li>
                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg
                      className="flex-shrink-0 size-3.5"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </span>
                  <span className="text-sm sm:text-base text-gray-500 ">
                    Administrative
                    Expenses
                    as % of
                    Work Done
                    After
                    Transformation:
                    8.52%
                  </span>
                </li>
                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg
                      className="flex-shrink-0 size-3.5"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </span>
                  <span className="text-sm sm:text-base text-gray-500 ">
                    Average
                    Work
                    Done/Year
                    After
                    Transformation:
                    INR 875.03
                    Million
                  </span>
                </li>
                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg
                      className="flex-shrink-0 size-3.5"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </span>
                  <span className="text-sm sm:text-base text-gray-500 ">
                    Average
                    Administrative
                    Expenses/Year
                    After
                    Transformation:
                    INR 74.61
                    Million
                  </span>
                </li>
                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg
                      className="flex-shrink-0 size-3.5"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </span>
                  <span className="text-sm sm:text-base text-gray-500 ">
                    Average
                    Annual
                    Income
                    After
                    Transformation:
                    INR 33.79
                    Million
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
