import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Info } from 'src/components/Icons';
import { projectData } from 'src/data';

export default function ProjectList() {
  return (
    <div className="max-w-7xl mx-auto">
      {/* STATS */}

      <div
        key="1"
        className="relative bg-gradient-to-bl from-secondary via-transparent rounded-xl "
      >
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid items-start relative md:grid-cols-2 gap-8 lg:gap-12">
            <div
              key="1"
              className="max-w-[85rem] sticky top-10 px-4  sm:px-6 lg:px-8  mx-auto"
            >
              <div className="w-full">
                <div
                  className="relative my-2"
                  data-hs-combo-box='{    "groupingType": "default",    "preventSelection": true,    "isOpenOnFocus": true,    "groupingTitleTemplate": "<div class=\"block text-xs text-gray-500 m-3 mb-1 dark:text-neutral-500 dark:border-neutral-700\"></div>"  }'
                >
                  <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                      <svg
                        className="flex-shrink-0 size-4 text-gray-400 "
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="11"
                          cy="11"
                          r="8"
                        />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </div>
                    <input
                      className="py-3 ps-10 pe-4 block w-full border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                      data-hs-combo-box-input=""
                      defaultValue=""
                      placeholder="Search for projects"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl ">
                  <div className="inline-flex justify-center items-center">
                    <span className="size-2 inline-block bg-gray-500 rounded-full me-2" />
                    <span className="text-xs font-semibold uppercase text-gray-600 ">
                      All
                      Projects
                    </span>
                  </div>
                  <div className="text-center">
                    <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 ">
                      <CountUp
                        end={Math.floor(
                          Math.random() *
                            100,
                        )}
                      />
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl ">
                  <div className="inline-flex justify-center items-center">
                    <span className="size-2 inline-block bg-green-500 rounded-full me-2" />
                    <span className="text-xs font-semibold uppercase text-gray-600 ">
                      Completed
                    </span>
                  </div>
                  <div className="text-center">
                    <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 ">
                      <CountUp
                        end={Math.floor(
                          Math.random() *
                            50,
                        )}
                      />
                    </h3>
                  </div>
                  <dl className="flex justify-center items-center divide-x divide-gray-200 dark:divide-neutral-800">
                    <dt className="pe-3">
                      <span className="text-green-600">
                        <svg
                          className="inline-block size-4 self-center"
                          fill="currentColor"
                          height="16"
                          viewBox="0 0 16 16"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                            fillRule="evenodd"
                          />
                        </svg>
                        <span className="inline-block text-sm">
                          5.6%
                        </span>
                      </span>
                      <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        change
                      </span>
                    </dt>
                  </dl>
                </div>
                <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl ">
                  <div className="inline-flex justify-center items-center">
                    <span className="size-2 inline-block bg-red-500 rounded-full me-2" />
                    <span className="text-xs font-semibold uppercase text-gray-600 ">
                      Ongoing
                    </span>
                  </div>
                  <div className="text-center">
                    <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 ">
                      <CountUp
                        end={Math.floor(
                          Math.random() *
                            10,
                        )}
                      />
                    </h3>
                  </div>
                  <dl className="flex justify-center items-center divide-x divide-gray-200 dark:divide-neutral-800">
                    <dt className="pe-3">
                      <span className="text-green-600">
                        <svg
                          className="inline-block size-4 self-center"
                          fill="currentColor"
                          height="16"
                          viewBox="0 0 16 16"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                            fillRule="evenodd"
                          />
                        </svg>
                        <span className="inline-block text-sm">
                          10.6%
                        </span>
                      </span>
                      <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        change
                      </span>
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3 p-5">
              {projectData.map(
                (project) => (
                  <div
                    key={
                      project.id
                    }
                    className="flex flex-col bg-white border shadow-sm rounded-xl "
                  >
                    <img
                      alt="Image Description"
                      className="w-full h-52 object-cover rounded-t-xl"
                      src={
                        project.image
                      }
                    />
                    <div className="p-4 md:p-5">
                      <h3 className="text-lg font-bold text-gray-800 ">
                        {
                          project.name
                        }
                      </h3>
                      <p className="mt-1 text-gray-500 ">
                        Contractor:{' '}
                        {
                          project.ContractorName
                        }
                      </p>
                      <Link
                        className="mt-2 py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        to="/project-details?id=1"
                      >
                        <Info />
                        <span>
                          View
                          Details
                        </span>
                      </Link>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
