import { Link, useNavigate } from 'react-router-dom';
import { UserIcon } from 'src/components/Icons';
import { useEffect, useState } from 'react';

export default function HeaderTop() {
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(
    localStorage.getItem('role') === '' ||
      localStorage.getItem('role') === null ||
      localStorage.getItem('role') === undefined
      ? false
      : true,
  );
  const handleRoleClickRedirect = (link: string) => {
    // localStorage.setItem('role', role);
    // setLoginStatus(true);
    navigate(link);
  };

  const handleLogout = () => {
    localStorage.clear();

    setLoginStatus(false);
    navigate('/');
  };

  // check login status
  // if not logged in, show login buttons
  // if logged in, show logout button

  useEffect(() => {
    if (
      localStorage.getItem('role') === '' ||
      localStorage.getItem('role') === null ||
      localStorage.getItem('role') === undefined
    ) {
      setLoginStatus(false);
    } else {
      setLoginStatus(true);
    }
  }, [loginStatus]);

  return (
    <div className="bg-primary w-full flex items-center justify-between px-5">
      <div className="m-2 flex flex-row space-x-2 items-start">
        <button>
          <img
            src="/static/kannada.png"
            alt="kannada"
            className="w-auto h-8  inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-500 text-gray-500 bg-white hover:border-gray-800 hover:text-gray-800 disabled:opacity-50 disabled:pointer-events-none"
          />
        </button>
      </div>
      {localStorage.getItem('role') === '' ||
      localStorage.getItem('role') === null ||
      localStorage.getItem('role') === undefined ? (
        <div className="flex flex-row  items-center space-x-5">
          <button
            onClick={() => handleRoleClickRedirect('/login/employee')}
            type="button"
            className="py-1 px-4  inline-flex items-center
          text-sm font-semibold rounded-lg border
          border-gray-500 text-gray-100 space-x-2 bg-tertiary hover:border-gray-800 hover:shadow-md disabled:opacity-50 disabled:pointer-events-none "
          >
            <svg
              className="icon icon-tabler icons-tabler-outline icon-tabler-user-shield"
              fill="none"
              height="24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" stroke="none" />
              <path d="M6 21v-2a4 4 0 0 1 4 -4h2" />
              <path d="M22 16c0 4 -2.5 6 -3.5 6s-3.5 -2 -3.5 -6c1 0 2.5 -.5 3.5 -1.5c1 1 2.5 1.5 3.5 1.5z" />
              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
            </svg>
            <span>Login</span>
          </button>
          <button
            onClick={() => handleRoleClickRedirect('/login/contractor')}
            type="button"
            className="py-1 px-4  inline-flex items-center
          text-sm font-semibold rounded-lg border
          border-gray-500 text-gray-100 space-x-2 bg-tertiary hover:border-gray-800 hover:shadow-md disabled:opacity-50 disabled:pointer-events-none "
          >
            <svg
              className="icon icon-tabler icons-tabler-outline icon-tabler-users-group"
              fill="none"
              height="24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" stroke="none" />
              <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
              <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" />
              <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
              <path d="M17 10h2a2 2 0 0 1 2 2v1" />
              <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
              <path d="M3 13v-1a2 2 0 0 1 2 -2h2" />
            </svg>
            <span>Contractor Login</span>
          </button>
        </div>
      ) : (
        <div className="flex flex-row  items-center space-x-5">
          <button
            onClick={() => handleLogout()}
            type="button"
            className="py-1 px-4  inline-flex items-center
        text-sm font-semibold rounded-lg border
        border-gray-500 text-gray-100 space-x-2 bg-secondary hover:border-gray-800 hover:shadow-md disabled:opacity-50 disabled:pointer-events-none "
          >
            <svg
              className="icon icon-tabler icons-tabler-outline icon-tabler-logout"
              fill="none"
              height="24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" stroke="none" />
              <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
              <path d="M9 12h12l-3 -3" />
              <path d="M18 15l3 -3" />
            </svg>
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
