import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useEffect, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  useTheme,
  Typography,
  Chip,
  OutlinedInput,
  styled,
  InputAdornment,
  Button,
  InputLabel,
  Select,
  Autocomplete,
} from '@mui/material';
import Footer from 'src/components/Footer';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import UploadImage from 'src/components/UploadImage';
import Checklist from 'src/content/dashboards/Tasks/Checklist';
import Profile from 'src/content/dashboards/Tasks/Profile';
import TeamOverview from 'src/content/dashboards/Tasks/TeamOverview';
import NewChecklist from 'src/content/applications/ProjectChecklist/NewChecklist';
import { useLocation } from 'react-router';
import ProjectDrawer from './ProjectDrawer';
import GoogleMapReact from 'google-map-react';
interface ProjectSchema {
  _id: string;
  projectCode: string;
  mainCategory: string;
  typeOfWork: string;
  workName: string;
  circle: string;
  ward: string;
  natureOfWork: string;
  department: string;
  description: string;
  fundType: string;
  budgetHead: string;
  functions: string;
  functionaries: string[];
  scheme: string;
  subScheme: string;
  estimatedCost: string;
  projectDurationStart: string;
  projectDurationEnd: string;
  ActualStartDate: string;
  ActualEndDate: string;
  contractor: string;
  approvalStatus: string;
  supportingDocuments: string[];
  remarks: string;
}

export default function ProjectDetails() {
  const theme = useTheme();
  const location = useLocation();
  // get id from location
  const projectId = location.pathname.split('/').pop();
  const [loading, setLoading] = useState(false);

  const [project, setProject] = useState<any>(null);

  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(30);

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  const fetchMyProjectDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/project-details/${
          JSON.parse(localStorage.getItem('user') || '{}').id
        }/${projectId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const data = await response.json();
      setProject(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyProjectDetails();
  }, []);

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  return (
    <>
      <Helmet>
        <title>Project Details</title>
      </Helmet>

      <Container maxWidth="lg" className="mt-10">
        <div className="flex items-start justify-between space-x-10">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            <Grid item xs={12}>
              <Card>
                <h1 className="text-2xl font-semibold text-left p-3">
                  {`${project?.workName} | Project Code: ${project?.projectCode}`}
                </h1>
                <Divider />

                <CardContent>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        background: `${theme.colors.alpha.black[5]}`,
                      }}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={12} md={6}>
                          <div className="w-full border rounded-xl p-5">
                            <Typography
                              variant="body2"
                              sx={{
                                px: 2,
                                pb: 2,
                              }}
                            >
                              <Box p={4}>
                                <Profile project={project} />
                              </Box>
                              <Chip
                                label={`Circle Name: ${project?.circle}`}
                                color="primary"
                                variant="outlined"
                              />
                              <Chip
                                label={`Ward Name: ${project?.ward}`}
                                color="primary"
                                variant="outlined"
                              />
                            </Typography>

                            <Divider />
                            <Typography
                              variant="body2"
                              sx={{
                                px: 2,
                                pb: 2,
                              }}
                            >
                              <div className="flex justify-start flex-col">
                                <h2 className="text-md pt-2 font-semibold">
                                  Contractor Details
                                </h2>
                                <Chip
                                  label={`Contractor: ${project?.contractor?.name}`}
                                  color="default"
                                  variant="outlined"
                                />
                              </div>
                            </Typography>
                            <NewChecklist
                              project={project}
                              user={JSON.parse(
                                localStorage.getItem('user') || '{}',
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {/* <Box p={4}>
                            <Profile />
                          </Box> */}

                          <div className="w-full border  rounded-xl">
                            <div className="grid divide-x-2 grid-cols-2">
                              <div className="flex flex-col p-4 space-y-5 divide-y-2">
                                <h1 className="text-2xl font-semibold">
                                  Project Info
                                </h1>

                                <ProjectDrawer intent="documents">
                                  <div className="text-lg">
                                    View/Upload Documents
                                  </div>
                                </ProjectDrawer>
                                <ProjectDrawer intent={'pictures'}>
                                  <div className="text-lg">
                                    View/Upload Pictures
                                  </div>
                                </ProjectDrawer>

                                <ProjectDrawer intent={'drawings'}>
                                  <div className="text-lg">
                                    View/Upload Drawings
                                  </div>
                                </ProjectDrawer>

                                <ProjectDrawer intent={'qc-reports'}>
                                  <div className="text-lg">
                                    View/Upload Q.C. Reports
                                  </div>
                                </ProjectDrawer>

                                <ProjectDrawer intent={'site-visits'}>
                                  <div className="text-lg">Site Visits</div>
                                </ProjectDrawer>
                              </div>
                              <div className="flex flex-col p-4 space-y-5 divide-y-2">
                                <h1 className="text-2xl font-semibold">
                                  Q.C. Format
                                </h1>
                                <div className="text-lg">Coarse Aggregate</div>
                                <div className="text-lg">Fine Aggregate</div>
                                <div className="text-lg">
                                  Physical Tests on Cement
                                </div>
                                <div className="text-lg">
                                  Sand for Deleterious Materials
                                </div>
                                <div className="text-lg">
                                  Test Reports on Steel
                                </div>
                              </div>
                            </div>
                          </div>
                          <h1 className="text-xl font-semibold text-left p-3">
                            Last Recorded Site Visit
                          </h1>
                          <div style={{ height: '100vh', width: '100%' }}>
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30350.056991085865!2d77.27913971452219!3d12.89548168955113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae379f152a9ed5%3A0x2d627ea0448da95a!2sSavanadurga%20Trek!5e0!3m2!1sen!2sin!4v1721630152690!5m2!1sen!2sin"
                              width="600"
                              height="450"
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>

                            <h1 className="text-xl font-semibold text-left p-3">
                              Recent Site Visit & Summary
                            </h1>
                            <div
                              key="1"
                              className="relative overflow-y-scroll  p-3 pt-10 bg-green-100 rounded-xl m-2"
                            >
                              <div className="flex gap-x-3 w-full hover:bg-gray-100">
                                <div className="w-16 text-end flex flex-col">
                                  <span className="text-xs text-gray-500">
                                    12:05PM
                                  </span>
                                  <span className="text-xs text-gray-500">
                                    11/7/2024
                                  </span>
                                </div>
                                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                                  <div className="relative z-10 size-7 flex justify-center items-center">
                                    <div className="size-2 rounded-full bg-gray-400" />
                                  </div>
                                </div>
                                <div className="grow pt-0.5 pb-8 ">
                                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                    <svg
                                      className="flex-shrink-0 size-4 mt-1"
                                      fill="none"
                                      height="24"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                                      <polyline points="14 2 14 8 20 8" />
                                      <line x1="16" x2="8" y1="13" y2="13" />
                                      <line x1="16" x2="8" y1="17" y2="17" />
                                      <line x1="10" x2="8" y1="9" y2="9" />
                                    </svg>
                                    Inspected the site
                                  </h3>
                                  <p className="mt-1 text-sm max-w-72 text-gray-600">
                                    The site was inspected and found to be in
                                    good condition. The work is progressing as
                                    planned.
                                  </p>

                                  <button
                                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                                    type="button"
                                  >
                                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                                      A
                                    </span>
                                    Aditya S - JE1BGM
                                  </button>
                                </div>
                              </div>
                              <div className="flex gap-x-3 w-full hover:bg-gray-100">
                                <div className="w-16 text-end flex flex-col">
                                  <span className="text-xs text-gray-500">
                                    2:05PM
                                  </span>
                                  <span className="text-xs text-gray-500">
                                    11/7/2024
                                  </span>
                                </div>
                                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                                  <div className="relative z-10 size-7 flex justify-center items-center">
                                    <div className="size-2 rounded-full bg-gray-400" />
                                  </div>
                                </div>
                                <div className="grow pt-0.5 pb-8">
                                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                    Raw Material Inspection
                                  </h3>
                                  <button
                                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                                    type="button"
                                  >
                                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                                      A
                                    </span>
                                    Aditya S - JE1BGM
                                  </button>
                                </div>
                              </div>
                              <div className="flex gap-x-3 w-full hover:bg-gray-100">
                                <div className="w-16 text-end flex flex-col">
                                  <span className="text-xs text-gray-500">
                                    10:05AM
                                  </span>
                                  <span className="text-xs text-gray-500">
                                    18/7/2024
                                  </span>
                                </div>
                                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                                  <div className="relative z-10 size-7 flex justify-center items-center">
                                    <div className="size-2 rounded-full bg-gray-400" />
                                  </div>
                                </div>
                                <div className="grow pt-0.5 pb-8">
                                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                    Water Supply Check
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-600">
                                    The water supply was checked and found to be
                                    in good condition. The work is progressing
                                    as planned.
                                  </p>
                                  <button
                                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                                    type="button"
                                  >
                                    <span className="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full">
                                      A
                                    </span>
                                    Aditya S - JE1BGM
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
    </>
  );
}
