import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Projects from './components/Main/Projects';
import ProjectList from './components/Main/Projects/list';
import HomeLayout from './layouts/HomeLayout';
import Organisation from './components/Main/Organisation';
import Compaints from './content/applications/Complaints';
import MyProjects from './content/pages/Components/MyProject';
import ProjectStatus from './content/applications/ProjectStatus';
import ProjectChecklist from './content/applications/ProjectChecklist';
import Financials from './content/applications/Financials';
import Accountability from './content/applications/Accountability';
import Login from './components/Main/Login';
import LoginContractor from './components/Main/LoginContractor';
import CreateProject from './content/applications/CreateProject';
import ProjectDetails from './content/applications/ProjectDetails';
import MyProjectDetails from './content/pages/Components/MyProject/Details';
import ProjectOverview from './content/ProjectOverviewCont';
import ProjectListCont from './content/ProjectOverviewCont/ProjectListCont';
import TenderForm from './content/ProjectOverviewCont/TenderForm';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Homepage = Loader(lazy(() => import('src/content/main')));

// Dashboards

const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger')),
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions')),
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile')),
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings')),
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons')),
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals')),
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions')),
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges')),
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips')),
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars')),
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404')),
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500')),
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon')),
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance')),
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <HomeLayout />,
    children: [
      {
        path: '/',
        element: <Homepage />,
      },
      {
        path: 'login/employee',
        element: <Login />,
      },
      {
        path: 'login/contractor',
        element: <LoginContractor />,
      },
      {
        path: 'project',
        element: <Projects />,
      },
      {
        path: 'district-page',
        element: <ProjectList />,
      },
      {
        path: 'organisation',
        element: <Organisation />,
      },

      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />,
      },
      {
        path: 'tasks',
        element: <Tasks />,
      },
      {
        path: 'messenger',
        element: <Messenger />,
      },
    ],
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />,
      },
      {
        path: 'transactions',
        element: <Transactions />,
      },
      {
        path: 'projectstatus',
        element: <ProjectStatus />,
      },
      {
        path: 'checklist',
        element: <ProjectChecklist />,
      },
      {
        path: 'create-project',
        element: <CreateProject />,
      },
      {
        path: 'project-details/:title',
        element: <ProjectDetails />,
      },
      {
        path: 'my-project-details/:title',
        element: <MyProjectDetails />,
      },
      {
        path: 'projectoverview',
        element: <ProjectOverview />,
      },
      {
        path: 'tender-form',
        element: <TenderForm />,
      },
      {
        path: 'districtpage',
        element: <ProjectListCont />,
      },
      {
        path: 'financials',
        element: <Financials />,
      },
      {
        path: 'accountability',
        element: <Accountability />,
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <UserProfile />,
          },
          {
            path: 'settings',
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />,
      },
      {
        path: 'buttons',
        element: <Buttons />,
      },
      {
        path: 'myproject',
        element: <MyProjects />,
      },
      {
        path: 'modals',
        element: <Modals />,
      },
      {
        path: 'accordions',
        element: <Accordions />,
      },
      {
        path: 'tabs',
        element: <Tabs />,
      },
      {
        path: 'badges',
        element: <Badges />,
      },
      {
        path: 'tooltips',
        element: <Tooltips />,
      },
      {
        path: 'avatars',
        element: <Avatars />,
      },
      {
        path: 'cards',
        element: <Cards />,
      },
      {
        path: 'forms',
        element: <Forms />,
      },
      {
        path: 'compaints',
        element: <Compaints />,
      },
    ],
  },
];

export default routes;
