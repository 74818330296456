import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useEffect, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  useTheme,
  Typography,
  Chip,
  Button,
  CardActions,
} from '@mui/material';
import Footer from 'src/components/Footer';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

import Switch from '@mui/material/Switch';
import UploadImage from 'src/components/UploadImage';
import Checklist from 'src/content/dashboards/Tasks/Checklist';
import Profile from 'src/content/dashboards/Tasks/Profile';
import { useNavigate } from 'react-router';
import MyPageHeader from './MyPageHeader';

const label = {
  inputProps: {
    'aria-label': 'Switch demo',
  },
};

const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

// {
//   mainCategory: 'Buildings',
//   typeOfWork: 'Building Construction',
//   workName: 'Public Dental Clinic',
//   circle: 'Circle 2',
//   ward: 'Ward 34',
//   natureOfWork: 'Capital',
//   department: 'Public Health',
//   description:
//     'This is a project involving health and safety of the public. A 3 standard floor construction is required. All the details are mentioned below in the documents along with the finances and construction quality requirements & Checks. ',
//   fundType: 'State Government Funds',
//   budgetHead: 'Mr. Rajamouli S',
//   functions: 'Others',
//   functionaries: [
//     '669682fe225983176496f5c5',
//     '6696837d225983176496f5c8',
//     '669683b0225983176496f5cb',
//   ],
//   scheme: 'SMART CITY',
//   subScheme: 'Govt.',
//   estimatedCost: '18500000',
//   projectDurationStart: '2024-07-20',
//   projectDurationEnd: '2025-12-19',
//   contractor: '66968675225983176496f5d4',
//   approvalStatus: 'Approved',
//   supportingDocuments: ['1721274527736-QC_report_dummy','1721274527736-Tender_for_Building_1_compressed'],
//   remarks:
//     'Check if all the documents are clear and cut. Then proceed after taking the initials and passing the bill. ',
// }

interface ProjectSchema {
  _id: string;
  projectCode: string;
  mainCategory: string;
  typeOfWork: string;
  workName: string;
  circle: string;
  ward: string;
  natureOfWork: string;
  department: string;
  description: string;
  fundType: string;
  budgetHead: string;
  functions: string;
  functionaries: string[];
  scheme: string;
  subScheme: string;
  estimatedCost: string;
  projectDurationStart: string;
  projectDurationEnd: string;
  ActualStartDate: string;
  ActualEndDate: string;
  contractor: string;
  approvalStatus: string;
  supportingDocuments: string[];
  remarks: string;
}

function MyProjects() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectSchema[]>([]);
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(30);

  const fetchMyProject = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/projects-details/${
          JSON.parse(localStorage.getItem('user') || '{}').id
        }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const data = await response.json();
      setProjects(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyProject();
  }, []);

  return (
    <>
      <Helmet>
        <title>My Projects</title>
      </Helmet>

      <PageTitleWrapper>
        <MyPageHeader />
      </PageTitleWrapper>

      <Container maxWidth="lg" className="pb-10">
        <h1 className="text-3xl  font-semibold   mb-5">My Assigned Projects</h1>
        {projects.map((project) => (
          <Grid item xs={12} md={4}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                background: `${theme.colors.alpha.black[5]}`,
              }}
            >
              <Box>
                <div className="">
                  <span className="text-tertiary">Code: </span>
                  {project.projectCode}
                </div>
              </Box>

              <span className="whitespace-pre-wrap text-xl font-semibold  line-clamp-3">
                {project.workName}
              </span>
              <Box
                sx={{
                  py: 2,
                }}
              >
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={project.approvalStatus}
                  color="success"
                />
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={project.ward}
                  color="secondary"
                />
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={'Category: ' + project.mainCategory}
                  color="secondary"
                />
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={'Type: ' + project.typeOfWork}
                  color="secondary"
                />
              </Box>
              <Typography
                sx={{
                  pb: 2,
                }}
                color="text.secondary"
              >
                {/* {project.ContractorName} */}
              </Typography>
              <Button
                onClick={() =>
                  navigate('/management/my-project-details/' + project._id)
                }
                size="small"
                variant="contained"
              >
                View details
              </Button>
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <CardActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Scheduled Start Date: {project.projectDurationStart}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Scheduled End Date: {project.projectDurationEnd}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Actual Start Date:{' '}
                  {project.projectDurationStart || 'Not Started'}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Actual End Date: {project.ActualEndDate || 'Not Completed'}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        ))}
        {/* <div className="flex items-start justify-between space-x-10">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            <Grid
              item
              xs={12}
            >
              <Card>
                <Chip
                  label="Status: Completed"
                  color="success"
                  variant="filled"
                  sx={{
                    m: 1,
                  }}
                />
                <CardHeader title="KERAGODU 12 PC QTRS - PG 2020 - PKG 10 - 2013-14 | Project ID: 7254" />
                <Divider />

                <CardContent>
                  <Grid
                    item
                    xs={12}
                  >
                    <Box
                      sx={{
                        background: `${theme.colors.alpha.black[5]}`,
                      }}
                    >
                      <Grid
                        container
                        spacing={
                          0
                        }
                      >
                        <Grid
                          item
                          xs={
                            12
                          }
                          md={
                            6
                          }
                        >
                          <Box
                            p={
                              4
                            }
                            sx={{
                              background: `${theme.colors.alpha.white[70]}`,
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                px: 2,
                                pb: 2,
                              }}
                            >
                              <Chip
                                label="Project Manager: AE1MANDYA"
                                color="primary"
                                variant="outlined"
                              />
                              <Chip
                                label="District Name: MANDYA"
                                color="primary"
                                variant="outlined"
                              />
                              <Chip
                                label="PHC/Contracts/PG-2020/LS/2014-15/12, Dt. 22.05.2014"
                                color="primary"
                                variant="outlined"
                              />
                              <Chip
                                label="PG Shetty Construction Technology Pvt Ltd"
                                color="primary"
                                variant="outlined"
                              />
                              <Chip
                                label="Project ID: 7254"
                                color="primary"
                                variant="outlined"
                              />
                            </Typography>
                            <Checklist />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={
                            12
                          }
                          md={
                            6
                          }
                        >
                          <Box
                            p={
                              4
                            }
                          >
                            <Profile />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div> */}
      </Container>
      <Footer />
    </>
  );
}

export default MyProjects;
