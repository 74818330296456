import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExcelUpload from './ExcelUpload';
import { Back, Check, Next } from 'src/components/Icons';
import { Fragment, useState } from 'react';

const stepsInfo = [
  'Upload any bills or receipts',
  'Upload any documents of the project',
  'Upload any Images of land survey,etc.',
];

const stepFileType = [
  'Excel files allowed. *.xlsx',
  'PDF files allowed. *.pdf',
  'Image files allowed. *.jpeg, *.png',
];

const steps = ['Bills or receipts', 'Documents', 'Images'];

export default function StepperComp({ handleClose }) {
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleImageUpload = async () => {
    const formData = new FormData();
    formData.append('image', image);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/upload/image`,
        {
          method: 'POST',
          body: formData,
        },
      )
        .then((res) => res.json())
        .then((data) => setImageUrl(data.url));
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  console.log('fileUrl', fileUrl);
  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/upload/file`,
        {
          method: 'POST',

          body: formData,
        },
      )
        .then((res) => res.json())
        .then((data) => setFileUrl(data.url));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);

    handleImageUpload();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);

    handleFileUpload();
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <div className="max-w-6xl flex flex-col items-center justify-between w-full mx-auto pt-20">
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          width: '100%',
          backgroundColor: 'transparent',
          color: 'gray-500',
          '& .MuiStepButton-root': {
            color: 'gray-500',
            '&.MuiStepButton-completed': {
              color: 'green',
            },
          },
        }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div className="w-full">
        {allStepsCompleted() ? (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              <div key="1" className=" h-screen">
                <div className="bg-white p-6  md:mx-auto">
                  <svg
                    className="text-green-600 w-16 h-16 mx-auto my-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <div className="text-center">
                    <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                      Files Uploaded Successfully!
                    </h3>
                    <p className="text-gray-600 my-2">
                      {' '}
                      Your files have been uploaded successfully. You can now
                      proceed to the next step.
                    </p>

                    <div className="py-10 text-center">
                      <button
                        className="px-12 bg-indigo-600 rounded-xl hover:bg-indigo-500 text-white font-semibold py-3"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex flex-col  w-full items-center justify-center ">
              <div className="flex items-center w-full justify-center">
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, display: 'inline-flex' }}
                >
                  <Back /> Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  onClick={handleNext}
                  sx={{ mr: 1, display: 'inline-flex' }}
                >
                  Next
                  <Next />
                </Button>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography
                      variant="caption"
                      sx={{ display: 'inline-block' }}
                    >
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1 ? (
                        <span className="inline-flex">
                          <Check /> Save & Finish
                        </span>
                      ) : (
                        <span className="inline-flex">
                          <Check /> Upload & Next
                        </span>
                      )}
                    </Button>
                  ))}
              </div>
              <div className="flex  pt-5 w-full flex-col items-start space-y-5 max-w-5xl mx-auto">
                {activeStep === 0 && (
                  <div className="flex  flex-col items-center w-full  justify-center">
                    <div className="flex items-center py-10 justify-center flex-col font-medium">
                      <svg
                        className="w-11 mb-2 fill-gray-500"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                          data-original="#000000"
                        />
                        <path
                          d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                          data-original="#000000"
                        />
                      </svg>
                      <span className="text-gray-500 font-semibold text-base">
                        {stepsInfo[activeStep]}
                      </span>

                      <p className="text-xs font-medium text-gray-600 mt-2">
                        {stepFileType[activeStep]}
                      </p>
                    </div>
                    <ExcelUpload />

                    {/* // display file preview */}
                    {fileUrl && (
                      <div className="flex items-center justify-center">
                        <img
                          src={fileUrl}
                          alt="file preview"
                          className="w-40 h-40 object-cover"
                        />
                      </div>
                    )}
                  </div>
                )}
                {activeStep === 1 && (
                  <div className="flex  flex-col items-center w-full  justify-center">
                    <div className="flex items-center py-10 justify-center flex-col font-medium">
                      <svg
                        className="w-11 mb-2 fill-gray-500"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                          data-original="#000000"
                        />
                        <path
                          d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                          data-original="#000000"
                        />
                      </svg>
                      <span className="text-gray-500 font-semibold text-base">
                        {stepsInfo[activeStep]}
                      </span>

                      <p className="text-xs font-medium text-gray-600 mt-2">
                        {stepFileType[activeStep]}
                      </p>
                    </div>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
                {activeStep === 2 && (
                  <div className="flex  flex-col items-center w-full  justify-center">
                    <div className="flex items-center py-10 justify-center flex-col font-medium">
                      <svg
                        className="w-11 mb-2 fill-gray-500"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                          data-original="#000000"
                        />
                        <path
                          d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                          data-original="#000000"
                        />
                      </svg>
                      <span className="text-gray-500 font-semibold text-base">
                        {stepsInfo[activeStep]}
                      </span>

                      <p className="text-xs font-medium text-gray-600 mt-2">
                        {stepFileType[activeStep]}
                      </p>
                    </div>
                    <input
                      type="file"
                      accept=".jpeg,.png,.jpg"
                      onChange={handleImageChange}
                    />
                  </div>
                )}

                {/* <label
                className="bg-white w-full text-center text-gray-500 font-semibold text-base rounded max-w-md h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]"
                htmlFor="uploadFile2"
              >
                <svg
                  className="w-11 mb-2 fill-gray-500"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  />
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  />
                </svg>
                Upload any documents (e.g. Reports, PDFs)
                <input className="hidden" id="uploadFile2" type="file" />
                <p className="text-xs font-medium text-center text-gray-400 mt-2">
                  PDFs allowed. *.pdf
                </p>
              </label>
              <label
                className="bg-white w-full text-gray-500 font-semibold text-base rounded max-w-md h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]"
                htmlFor="uploadFile3"
              >
                <svg
                  className="w-11 mb-2 fill-gray-500"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  />
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  />
                </svg>
                Upload any Images of land survey,etc.
                <input className="hidden" id="uploadFile3" type="file" />
                <UploadImage
                  images={images}
                  setImages={setImages}
                  error={error}
                />
                <p className="text-xs font-medium text-gray-400 mt-2">
                  jpg, jpeg, png allowed.
                </p>
              </label> */}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}
