import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
        <div className="col-span-full hidden lg:col-span-1 lg:block">
          <Link
            aria-label="Brand"
            className="flex text-lg items-center  font-semibold text-gray-900  gap-x-2"
            to="/"
          >
            <img
              src="/static/logo.jpeg"
              alt="Karnataka State Police Housing Corporation Limited"
              className="w-auto h-8  inline-flex items-center  "
            />
            <span>
              KSPH & IDCL.ORG
            </span>
          </Link>
          <p className="mt-3 text-xs sm:text-sm text-gray-600 ">
            ©{' '}
            {new Date().getFullYear()}{' '}
            All rights
            reserved. KSPH &
            IDCL.ORG
          </p>
        </div>
        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase ">
            Group
          </h4>
          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Highlights
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Financials
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Accouncements
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Events
              </Link>
            </p>
          </div>
        </div>
        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase ">
            Organization
          </h4>
          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="/about-us"
              >
                About us
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="/blogs"
              >
                Blogs
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="/careers"
              >
                Careers
              </Link>{' '}
              <span className="inline text-tertiary ">
                — We're hiring
              </span>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                RTI
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Vision
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Mission
              </Link>
            </p>
          </div>
        </div>
        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase ">
            Resources
          </h4>
          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                CSR
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                IMS Documents
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Contractor
                Registeration
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Meetings and
                Proceedings
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Status
              </Link>
            </p>
          </div>
        </div>
        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase ">
            Registry
          </h4>
          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Testimonials
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Complaints
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Policies &
                Procedures
              </Link>{' '}
              <span className="inline text-tertiary ">
                — New
              </span>
            </p>
          </div>
          <h4 className="mt-7 text-xs font-semibold text-gray-900 uppercase ">
            Industries
          </h4>
          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Status Of
                Projects
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800  "
                to="#"
              >
                Police Dpt
                websites Links
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
