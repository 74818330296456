import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
} from '@mui/material';
import Footer from 'src/components/Footer';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

import Switch from '@mui/material/Switch';
import UploadImage from 'src/components/UploadImage';

const label = {
  inputProps: {
    'aria-label':
      'Switch demo',
  },
};

const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

function Compaints() {
  const [
    currency,
    setCurrency,
  ] = useState('EUR');

  const handleChange = (
    event,
  ) => {
    setCurrency(
      event.target.value,
    );
  };

  const [images, setImages] =
    useState([]);
  const [error, setError] =
    useState(null);
  const [value, setValue] =
    useState(30);

  const handleChange2 = (
    event,
    newValue,
  ) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>
          Contractor Desk
        </title>
      </Helmet>

      <Container
        maxWidth="lg"
        className="mt-10"
      >
        <div className="flex items-start justify-between space-x-10">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            <Grid
              item
              xs={12}
            >
              <Card>
                <CardHeader title="Complaint & Feedback Form" />
                <Divider />
                <CardContent>
                  <div className="grid w-full grid-cols-2 gap-4">
                    <TextField
                      required
                      id="outlined-required"
                      label="Complaint Title"
                      defaultValue=""
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Complaint Subject"
                      defaultValue=""
                    />
                    <div className="w-full col-span-2">
                      <TextField
                        required
                        id="outlined-required"
                        label="Complaint Description"
                        defaultValue=""
                        multiline
                        rows={
                          4
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                </CardContent>
                <CardActions>
                  {/* //Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={
                      <svg
                        className="icon icon-tabler icons-tabler-outline icon-tabler-mail-forward"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 0h24v24H0z"
                          fill="none"
                          stroke="none"
                        />
                        <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                        <path d="M3 6l9 6l9 -6" />
                        <path d="M15 18h6" />
                        <path d="M18 15l3 3l-3 3" />
                      </svg>
                    }
                  >
                    Send
                    Message
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Compaints;
