import {
  Typography,
  Tooltip,
  Rating,
  IconButton,
  Divider,
  Box,
  ListItem,
  ListItemText,
  List,
  Avatar,
  useTheme,
} from '@mui/material';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';

function Profile({ project }) {
  const theme = useTheme();

  return (
    <Box>
      {/* <Avatar
        sx={{
          mx: 'auto',
          mb: 1.5,
          width: theme.spacing(12),
          height: theme.spacing(12)
        }}
        variant="rounded"
        alt="Craig Donin"
        src="/static/images/avatars/4.jpg"
      /> */}

      <img
        src="/static/projects/temp/1.jpg"
        alt="Project Image"
        className="object-cover w-full mb-4 rounded-lg h-96"
      />
      <Typography align="center" variant="h4" gutterBottom>
        {project?.workName} | Project Code: {project?.projectCode}
      </Typography>
      <Typography align="center" variant="subtitle2" gutterBottom>
        {project?.contractor?.name}
      </Typography>

      <Typography align="center" variant="subtitle2" gutterBottom>
        Ward No: {project?.ward} | Circle: {project?.circle}
      </Typography>
      <Typography align="center" variant="subtitle2" gutterBottom>
        Project Manager: Aditya S
      </Typography>

      <Box py={2} display="flex" alignItems="center" justifyContent="center">
        <Tooltip arrow placement="top" title="Call">
          <IconButton
            color="primary"
            sx={{
              mx: 0.5,
            }}
          >
            <PhoneTwoToneIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement="top" title="Send email">
          <IconButton
            color="primary"
            sx={{
              mx: 0.5,
            }}
          >
            <EmailTwoToneIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement="top" title="Send message">
          <IconButton
            color="primary"
            sx={{
              mx: 0.5,
            }}
          >
            <MessageTwoToneIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <List
        sx={{
          px: 2,
        }}
      >
        <Divider component="li" />
        <ListItem
          sx={{
            py: 1.5,
          }}
        >
          <ListItemText
            primary="Schedule Start Date"
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
          />
          <Typography variant="subtitle2" color="text.primary">
            {project?.projectDurationStart}
          </Typography>
        </ListItem>

        <Divider component="li" />
        <ListItem
          sx={{
            py: 1.5,
          }}
        >
          <ListItemText
            primary="Actual Start Date"
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
          />
          <Typography variant="subtitle2" color="text.primary">
            {project?.projectDurationStart}
          </Typography>
        </ListItem>
        <Divider component="li" />
        <ListItem
          sx={{
            py: 1.5,
          }}
        >
          <ListItemText
            primary="Scheduled End Date"
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
          />
          <Typography variant="subtitle2" color="text.primary">
            {project?.projectDurationEnd}
          </Typography>
        </ListItem>
        <Divider component="li" />
        <ListItem
          sx={{
            py: 1.5,
          }}
        >
          <ListItemText
            primary="Actual End Date"
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
          />
          <Typography variant="subtitle2" color="text.primary">
            -
          </Typography>
        </ListItem>
        <Divider component="li" />
        <ListItem
          sx={{
            py: 1.5,
          }}
        >
          <ListItemText
            primary="Total Duration"
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
          />
          <Typography variant="subtitle2" color="text.primary">
            1 year 5 months 0 days
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
}

export default Profile;
