import {
  useRef,
  useState,
} from 'react';
import {
  Button,
  Box,
  Menu,
  alpha,
  MenuItem,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';

const DotPrimaryLight =
  styled('span')(
    ({ theme }) => `
    border-radius: 22px;
    background: ${
      theme.colors.primary
        .lighter
    };
    width: ${theme.spacing(
      1.5,
    )};
    height: ${theme.spacing(
      1.5,
    )};
    display: inline-block;
    margin-right: ${theme.spacing(
      0.5,
    )};
`,
  );

const DotPrimary = styled(
  'span',
)(
  ({ theme }) => `
    border-radius: 22px;
    background: ${
      theme.colors.primary
        .main
    };
    width: ${theme.spacing(
      1.5,
    )};
    height: ${theme.spacing(
      1.5,
    )};
    display: inline-block;
    margin-right: ${theme.spacing(
      0.5,
    )};
`,
);

function TasksAnalytics() {
  const theme = useTheme();

  const chartOptions: ApexOptions =
    {
      chart: {
        background:
          'transparent',
        type: 'bar',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 6,
          columnWidth: '35%',
        },
      },
      colors: [
        theme.colors.primary
          .main,
        alpha(
          theme.colors.primary
            .main,
          0.5,
        ),
      ],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      theme: {
        mode: theme.palette
          .mode,
      },
      stroke: {
        show: true,
        width: 3,
        colors: [
          'transparent',
        ],
      },
      legend: {
        show: false,
      },
      labels: [
        '2020-21',
        '2019-20',
        '2019-19',
        '2017-18',
        '2016-17',
        '2017-18',
        '2018-19',
        '2019-20',
        '2020-21',
        '2021-22',
        '2022-23',
      ],
      grid: {
        strokeDashArray: 5,
        borderColor:
          theme.palette
            .divider,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors:
              theme.palette
                .text
                .secondary,
          },
        },
      },
      yaxis: {
        tickAmount: 6,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors:
              theme.palette
                .text
                .secondary,
          },
        },
      },
      tooltip: {
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
        y: {
          formatter:
            function (val) {
              return (
                '$ ' +
                val +
                'k'
              );
            },
        },
        theme: 'dark',
      },
    };

  const chartData = [
    {
      name: 'Cost in Crores',
      data: [
        30000, 45000, 70000, 50000, 40000,30000, 45000, 70000, 50000, 40000,20000
      ],
    },
  ];

  return (
    <Box>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">
          Direct Work
          Expenditure
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        pb={2}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: 'flex',
            alignItems:
              'center',
            mr: 2,
          }}
        >
          <DotPrimary />
          Cost in Crores
        </Typography>
      </Box>
      <Chart
        options={chartOptions}
        series={chartData}
        type="bar"
        height={270}
      />
    </Box>
  );
}

export default TasksAnalytics;
