export default function Organisation() {
  const staffMembers = [
    {
      id: 1,
      name: 'SHRI. DR. K RAMCHANDRA RAO',
      position:
        'IPS, Director General of Police, Chairman and Managing Director, KSPH&IDCL',
    },
    {
      id: 2,
      name: 'SHRI S MURUGAN',
      position:
        'IPS, Additional Director General of Police, CL & M, Director, KSPH&IDCL',
    },
    {
      id: 3,
      name: 'SHRI. S. RAVI',
      position:
        'IPS, Home Secretary, Director, KSPH&IDCL',
    },
    {
      id: 4,
      name: 'SMT. MALINI KRISHNAMURTHI',
      position:
        'IPS, Additional Director General of Police, Prison & Correctional Service, Director, KSPH&IDCL',
    },
    {
      id: 5,
      name: 'SMT. MANGALA GANDLAHALLI SRINIVASAN',
      position:
        'Additional Secretary (Police Service), Home Department, Director, KSPH&IDCL',
    },
  ];
  return (
    <section className="text-gray-600 body-font">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
              Organization
            </h2>
            <h1 className="sm:text-3xl text-primary text-2xl font-semibold title-font mb-4 ">
              Our Mission
            </h1>
            <p className=" mx-auto text-justify leading-relaxed text-base">
              Karnataka State
              Police Housing
              and
              Infrastructure
              development
              Corporation
              Limited
              (KSPH&IDCL), a
              company of the
              Government of
              Karnataka, is
              committed to
              designing and
              constructing
              police buildings
              in an innovative
              manner.
              <br />
              <br />
              As public money
              is involved, the
              Karnataka
              government seeks
              Transparency in
              Public works and
              the Right to
              Information to
              its citizens,
              and KSPH&IDCL
              makes available
              all relevant
              information of
              its activities
              on its portal.
              <br />
              <br />
              By creating its
              own portal and
              providing
              information to
              the public,
              KSPH&IDCL hopes
              to serve the
              public in a
              better and more
              effective
              manner.
            </p>
          </div>
          <h1 className="sm:text-3xl text-2xl font-semibold title-font  text-primary">
            Our Objectives
          </h1>
          <p className=" mx-auto text-justify leading-relaxed text-base mb-4">
            The objectives to
            be pursued by the
            KSPH&IDCL:
          </p>
          <div className="grid grid-cols-3 w-full gap-5">
            <div className="w-full  px-8 py-6 rounded-xl border shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Government
                Employee
                Housing
              </h2>
              <p className="leading-relaxed text-base mb-4">
                To construct
                residential
                buildings for
                the employees
                of Karnataka's
                Police,
                Prisons, Home
                Guards, and
                Fire Force
                departments,
                providing safe
                and
                comfortable
                living spaces.
              </p>
            </div>
            <div className="w-full px-8 py-6 rounded-xl border shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Departmental
                Infrastructure
                Development
              </h2>
              <p className="leading-relaxed text-base mb-4">
                To build
                administrative
                offices,
                police
                stations,
                prisons, fire
                stations, and
                other
                essential
                facilities for
                the Police,
                Prisons, and
                Home Guards
                departments,
                enhancing
                their
                operational
                efficiency.
              </p>
            </div>
            <div className="w-full px-8 py-6 rounded-xl border shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Public Service
                Buildings
              </h2>
              <p className="leading-relaxed text-base mb-4">
                To undertake
                the
                construction
                of schools,
                hospitals,
                health
                centers,
                shops, clubs,
                auditoriums,
                maternity
                homes, and
                other public
                service
                buildings as
                entrusted by
                the
                Government,
                ensuring
                quality
                infrastructure
                for the
                community.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div
        key="1"
        className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
      >
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-2xl text-primary font-bold md:text-4xl md:leading-tight ">
            Present Directors
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {staffMembers.map(
            (member) => (
              <div
                key={
                  member.id
                }
                className="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-gray-200 "
              >
                <div className="flex items-center gap-x-4">
                  <div className="grow">
                    <h3 className="text-xl text-tertiary font-bold">
                      {
                        member.name
                      }
                    </h3>
                  </div>
                </div>
                <p className="mt-3 text-gray-500 dark:text-neutral-500">
                  {
                    member.position
                  }
                </p>
              </div>
            ),
          )}
        </div>
      </div>
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
            Corporation Staff
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            The KSPH&IDCL team
            is composed of a
            group of dedicated
            professionals with
            a wide range of
            expertise and
            experience.
          </p>
        </div>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          <div className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span className="title-font font-medium">
                Financial
                Advisor
              </span>
            </div>
          </div>
          <div className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span className="title-font font-medium">
                Chief Engineer
              </span>
            </div>
          </div>
          <div className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span className="title-font font-medium">
                Superintending
                Engineers
              </span>
            </div>
          </div>
          <div className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span className="title-font font-medium">
                Executive
                Engineers
              </span>
            </div>
          </div>
          <div className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span className="title-font font-medium">
                Assistant
                Executive
                Engineers
              </span>
            </div>
          </div>
          <div className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span className="title-font font-medium">
                Assistant
                Engineers
              </span>
            </div>
          </div>
          <div className="p-2 sm:w-1/2 w-full">
            <div className="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span className="title-font font-medium">
                Junior
                Engineers
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
