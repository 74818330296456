import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Box,
  FormControl,
  CardActions,
  Typography,
  Avatar,
  Divider,
  Link,
  Rating,
  OutlinedInput,
  Chip,
  Tooltip,
  AvatarGroup,
  Pagination,
  InputAdornment,
  Menu,
  MenuItem,
  styled,
  useTheme,
  LinearProgress,
  Container,
} from '@mui/material';
import { formatDistance, subMonths, subDays } from 'date-fns';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Text from 'src/components/Text';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { projectData } from 'src/data';
import { Plus } from 'src/components/Icons';

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      padding-right: ${theme.spacing(0.7)}
  `,
);

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
          flex-grow: 1;
          height: 10px;
          
          &.MuiLinearProgress-root {
            background-color: ${theme.colors.alpha.black[10]};
          }
          
          .MuiLinearProgress-bar {
            border-radius: ${theme.general.borderRadiusXl};
          }
  `,
);

function ProjectStatus() {
  const theme = useTheme();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [projects, setProjects] = useState([]);

  const handleDelete = () => {};

  const handleClick = () => {};

  const fetchAllProjects = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/projects-list`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const data = await response.json();
      setProjects(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  const periods = [
    {
      value: 'recent',
      text: 'Recent projects',
    },
    {
      value: 'updated',
      text: 'Latest updated projects',
    },
    {
      value: 'oldest',
      text: 'Oldest projects first',
    },
  ];

  const actionRef1 = useRef<any>(null);
  const [openPeriod, setOpenMenuPeriod] = useState<boolean>(false);
  const [period, setPeriod] = useState<string>(periods[0].text);

  return (
    <Container maxWidth="lg" className="py-10">
      <div className="flex justify-start my-2">
        <Button
          variant="contained"
          component={RouterLink}
          to="/management/create-project"
          startIcon={<Plus />}
        >
          Create New Project
        </Button>
      </div>
      <FormControl variant="outlined" fullWidth>
        <OutlinedInputWrapper
          type="text"
          placeholder="Search terms here..."
          endAdornment={
            <InputAdornment position="end">
              <Button variant="contained" size="small">
                Search
              </Button>
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment position="start">
              <SearchTwoToneIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <Box
        py={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle2">
            Showing{' '}
            <Text color="black">
              <b>{projects.length + 5} Projects</b>
            </Text>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="subtitle2"
            sx={{
              pr: 1,
            }}
          >
            Sort by:
          </Typography>
          <Button
            size="small"
            variant="outlined"
            ref={actionRef1}
            onClick={() => setOpenMenuPeriod(true)}
            endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
          >
            {period}
          </Button>
          <Menu
            disableScrollLock
            anchorEl={actionRef1.current}
            onClose={() => setOpenMenuPeriod(false)}
            open={openPeriod}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {periods.map((_period) => (
              <MenuItem
                key={_period.value}
                onClick={() => {
                  setPeriod(_period.text);
                  setOpenMenuPeriod(false);
                }}
              >
                {_period.text}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {projects.map((project) => (
          <Grid item xs={12} md={6} xl={6}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                height: '480px',
                background: `${theme.colors.alpha.black[10]}`,
              }}
              className="hover:shadow-xl hover:bg-gray-200  hover:border-tertiary"
            >
              <Box>
                <div className="">
                  <span className="text-tertiary">Code: </span>
                  {project.projectCode}
                </div>
              </Box>

              <span className="whitespace-pre-wrap text-xl font-semibold  line-clamp-3">
                {project.workName}
              </span>
              <Box
                sx={{
                  py: 2,
                }}
              >
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={project.approvalStatus}
                  color="success"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />

                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={project.ward}
                  color="secondary"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={'Category: ' + project.mainCategory}
                  color="secondary"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={'Type: ' + project.typeOfWork}
                  color="secondary"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
              </Box>
              <Typography
                sx={{
                  pb: 2,
                }}
                color="text.secondary"
              >
                {/* {project.ContractorName} */}
              </Typography>
              <Button
                onClick={() =>
                  navigate(`/management/project-details/${project._id}`)
                }
                size="small"
                variant="contained"
              >
                View details
              </Button>
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <CardActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Scheduled Start Date: {project.projectDurationStart}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Scheduled End Date: {project.projectDurationEnd}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Actual Start Date: {project.ActualStartDate || 'Not Started'}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Actual End Date: {project.ActualEndDate || 'Not Completed'}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        ))}

        {projectData.map((project) => (
          <Grid item xs={12} md={6} xl={6}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                background: `${theme.colors.alpha.black[5]}`,
                height: '450px',
              }}
            >
              <Box>
                <div className="">
                  <span className="text-tertiary">Code: </span>
                  {project.ProjectCode}
                </div>
              </Box>

              <span className="whitespace-pre-wrap text-xl font-semibold  line-clamp-3">
                {project.name}
              </span>
              <Box
                sx={{
                  py: 2,
                }}
              >
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label="Completed"
                  color="success"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
                <Chip
                  sx={{
                    mr: 0.5,
                  }}
                  size="small"
                  label={project.DistrictName}
                  color="secondary"
                  onClick={handleClick}
                  onDelete={handleDelete}
                />
              </Box>
              <Typography
                sx={{
                  pb: 2,
                }}
                color="text.secondary"
              >
                {project.ContractorName}
              </Typography>
              {/* <Button disabled size="small" variant="contained">
                View details
              </Button> */}
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <CardActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Scheduled Start Date: {project.ScheduledStartDate}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Scheduled End Date: {project.ScheduledEndDate}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Actual Start Date: {project.ActualStartDate}
                </Typography>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle2"
                >
                  <TodayTwoToneIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  Actual End Date: {project.ActualEndDate}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          pt: 4,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          showFirstButton
          showLastButton
          count={15}
          defaultPage={6}
          siblingCount={0}
          size="large"
          shape="rounded"
          color="primary"
        />
      </Box>
    </Container>
  );
}

export default ProjectStatus;
