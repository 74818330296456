import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useEffect, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
  CardActions,
  Button,
} from '@mui/material';
import Footer from 'src/components/Footer';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

import Switch from '@mui/material/Switch';
import UploadImage from 'src/components/UploadImage';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import UploadModal from 'src/content/pages/Components/Forms/UploadModal';

const label = {
  inputProps: {
    'aria-label': 'Switch demo',
  },
};

const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

function TenderForm() {
  const navigate = useNavigate();
  const [contractor, setContractor] = useState({
    tenderId: 'PG-2024/92',
    tenderTitle: 'SAVANDURGA BASE TREK BOUNDARY WALL WATER CONNECTION',
    description: '',
    startDate: '2024-10-10',
    endDate: '2025-01-09',
    submissionDeadline: '2025-01-10',
    budget: '18,00,000',
    contractorName: 'P G Shetty',
    businessName: 'P G Shetty Construction Technologies Pvt. Ltd.',
    businessType: 'Construction & Infrastructure',
    registrationNumber: '1A2B3C4D5E6F7G8H9I0J',
    dateOfRegistration: '2021-10-10',
    contactPersonName: 'P G Shetty',
    address:
      '123, 4th Cross, 5th Main, 6th Block, Koramangala, Bangalore - 560095',
    contactNumber: '9876543210',
    panNumber: 'A1B2C3D4E5',
    gstNumber: 'A1B2C3D4E5F6G7H8I9J0K1L2M3N4',
    email: 'pgshettyconstructions@gmail.com',
    password: 'password',
  });
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);

  const [value, setValue] = useState(30);

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setTimeout(() => {
        toast.success('Tender Application Submitted Successfully');
        setLoading(false);
        navigate('/components/myproject');
      }, 2000);
    } catch (error) {
      toast.error('Error in Registering Contractor');
    }
  };

  useEffect(() => {
    const requestLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (err) => {
            setError(err.message);
          },
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };

    requestLocation();
  }, []);

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Contractor Desk</title>
      </Helmet>

      <Container maxWidth="lg" className="mt-10">
        <div className="flex items-start justify-between space-x-10">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={5}
          >
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Tender Registration Form" />
                <Divider />
                <CardContent>
                  <div className="grid w-full grid-cols-2 gap-4">
                    <div className="w-full col-span-2">
                      <h2 className="text-xl font-semibold">Tender Details</h2>
                    </div>

                    <TextField
                      required
                      id="outlined-required"
                      label="Tender ID"
                      defaultValue=""
                      value={contractor.tenderId}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          tenderId: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Tender Title"
                      defaultValue=""
                      value={contractor.tenderTitle}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          tenderTitle: e.target.value,
                        })
                      }
                      className="col-span-2"
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Start Date"
                      type="date"
                      defaultValue=""
                      value={contractor.startDate}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          startDate: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="End Date"
                      type="date"
                      defaultValue=""
                      value={contractor.endDate}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          endDate: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Submission Deadline"
                      type="date"
                      defaultValue=""
                      value={contractor.submissionDeadline}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          submissionDeadline: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Budget"
                      defaultValue=""
                      value={contractor.budget}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          budget: e.target.value,
                        })
                      }
                    />

                    <TextField
                      rows={4}
                      id="outlined-required"
                      label="Tender Description (Optional)"
                      defaultValue=""
                      value={contractor.description}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          description: e.target.value,
                        })
                      }
                      className="col-span-2"
                    />

                    <Divider />
                    <div className="w-full col-span-2">
                      <h2 className="text-xl font-semibold">Basic Details</h2>
                    </div>
                    <TextField
                      required
                      id="outlined-required"
                      label="Contractor Name"
                      defaultValue=""
                      value={contractor.contractorName}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          contractorName: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Contact Person Name"
                      defaultValue=""
                      value={contractor.contactPersonName}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          contactPersonName: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Address"
                      defaultValue=""
                      value={contractor.address}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          address: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Contact Number"
                      defaultValue=""
                      value={contractor.contactNumber}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          contactNumber: e.target.value,
                        })
                      }
                    />

                    <Divider />
                    <div className="w-full col-span-2">
                      <h2 className="text-xl font-semibold">
                        Business Details
                      </h2>
                    </div>

                    <TextField
                      required
                      disabled
                      id="outlined-required"
                      label="Business Name"
                      defaultValue=""
                      value={contractor.businessName}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          businessName: e.target.value,
                        })
                      }
                    />
                    <TextField
                      required
                      disabled
                      id="outlined-required"
                      label="Business Type"
                      defaultValue=""
                      value={contractor.businessType}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          businessType: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      disabled
                      id="outlined-required"
                      label="Registration Number"
                      defaultValue=""
                      value={contractor.registrationNumber}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          registrationNumber: e.target.value,
                        })
                      }
                    />
                    <TextField
                      required
                      disabled
                      id="outlined-required"
                      label="Date of Registration"
                      defaultValue=""
                      value={contractor.dateOfRegistration}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          dateOfRegistration: e.target.value,
                        })
                      }
                    />
                    <Divider />
                    <div className="w-full col-span-2">
                      <h2 className="text-xl font-semibold">
                        Financial Details
                      </h2>
                    </div>

                    <TextField
                      required
                      disabled
                      id="outlined-required"
                      label="PAN Number"
                      defaultValue=""
                      value={contractor.panNumber}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          panNumber: e.target.value,
                        })
                      }
                    />

                    <TextField
                      required
                      disabled
                      id="outlined-required"
                      label="GST Number"
                      defaultValue=""
                      value={contractor.gstNumber}
                      onChange={(e) =>
                        setContractor({
                          ...contractor,
                          gstNumber: e.target.value,
                        })
                      }
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <div className="flex flex-col items-start space-y-5 justify-start">
            <UploadModal />
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                handleSubmit();
              }}
              className="w-full"
              startIcon={
                loading ? (
                  <svg
                    className="icon icon-tabler animate-spin icons-tabler-outline icon-tabler-loader-2"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none" stroke="none" />
                    <path d="M12 3a9 9 0 1 0 9 9" />
                  </svg>
                ) : (
                  <svg
                    className="icon icon-tabler icons-tabler-outline icon-tabler-device-floppy"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none" stroke="none" />
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                    <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M14 4l0 4l-6 0l0 -4" />
                  </svg>
                )
              }
            >
              Submit Tender Application
            </Button>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default TenderForm;
