import {
  Box,
  Grid,
  Typography,
  Avatar,
  Badge,
  Tooltip,
  useTheme,
  LinearProgress,
  styled,
} from '@mui/material';
import { formatDistance, subDays, subMinutes, subHours } from 'date-fns';
import Text from 'src/components/Text';
import { projectData } from 'src/data';

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
    border: ${theme.colors.alpha.white[100]} solid 2px;
`,
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`,
);

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
        flex-grow: 1;
        height: 10px;
        
        &.MuiLinearProgress-root {
          background-color: ${theme.colors.alpha.black[10]};
        }
        
        .MuiLinearProgress-bar {
          border-radius: ${theme.general.borderRadiusXl};
        }
`,
);

function TeamOverview() {
  const theme = useTheme();

  return (
    <div>
      <div className="flex flex-col m-auto p-auto">
        <div className="flex overflow-x-scroll pb-10 ">
          <div className="flex flex-nowrap">
            {projectData.map((project) => (
              <div className="inline-block px-3">
                <div className="w-96 p-5 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <Box display="flex" alignItems="center" pb={3}>
                    <Badge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      overlap="circular"
                      badgeContent={
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            'Offline since ' +
                            formatDistance(
                              subDays(new Date(), 14),
                              new Date(),
                              {
                                addSuffix: true,
                              },
                            )
                          }
                        >
                          <DotLegend
                            style={{
                              background: `${theme.colors.error.main}`,
                            }}
                          />
                        </Tooltip>
                      }
                    >
                      <AvatarWrapper alt="Remy Sharp" src={project.image} />
                    </Badge>
                    <Box
                      sx={{
                        ml: 1.5,
                      }}
                    >
                      {/* <Typography
                    variant="h4"
                    gutterBottom
                    textOverflow="ellipsis"
                  >
                    {
                      project.name
                    }
                  </Typography> */}
                      <h3 className="text-md font-bold line-clamp-3 text-gray-800 ">
                        {project.name}
                      </h3>
                      <p className="mt-1 text-gray-500 ">
                        {project.ContractorName}
                      </p>
                      {/* <Typography
                    variant="subtitle2"
                    noWrap
                  >
                    {
                      project.ContractorName
                    }
                  </Typography> */}
                    </Box>
                  </Box>

                  <Typography variant="subtitle2" gutterBottom>
                    <Text color="black">{Math.floor(Math.random() * 5)}</Text>{' '}
                    out of{' '}
                    <Text color="black">
                      {Math.floor(Math.random() * 10) + 5}
                    </Text>{' '}
                    checklists completed
                  </Typography>
                  <LinearProgressWrapper
                    value={Math.floor(Math.random() * 100)}
                    color="primary"
                    variant="determinate"
                  />
                </div>
              </div>
            ))}
            {/* <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" />
            </div> */}
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '.hide-scroll-bar {  -ms-overflow-style: none;  scrollbar-width: none;}.hide-scroll-bar::-webkit-scrollbar {  display: none;}',
        }}
      />
    </div>
  );
}

export default TeamOverview;
