import React, { useRef, useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Spreadsheet from 'x-data-spreadsheet';

const EXTENSIONS = ['xlsx', 'xls', 'csv'];

const ExcelUpload = () => {
  const [error, setError] = useState('');
  const [done, setDone] = useState(false);
  const spreadsheetRef = useRef(null); // Ref to the x-spreadsheet instance

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && validateFile(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        processWorkbook(XLSX.read(data, { type: 'array' }));
      };
      reader.readAsArrayBuffer(file);
    } else {
      showError('Invalid file type. Please upload an Excel file.');
    }
  };

  const validateFile = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return EXTENSIONS.includes(fileExtension);
  };

  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(''), 3000); // Clear error message after 3 seconds
  };

  const processWorkbook = (wb) => {
    const data = stox(wb);
    new Spreadsheet(
      '#x-spreadsheet-demo', // default options
      {
        mode: 'edit', // edit | read
        showToolbar: true,
        showGrid: true,
        showContextmenu: true,
        view: {
          height: () => 500,
          width: () => 1000,
        },
        row: {
          len: 100,
          height: 25,
        },
        col: {
          len: 26,
          width: 100,
          indexWidth: 60,
          minWidth: 60,
        },
        style: {
          bgcolor: '#ffffff',
          align: 'left',
          valign: 'middle',
          textwrap: false,
          strike: false,
          underline: false,
          color: '#0a0a0a',
          font: {
            name: 'Helvetica',
            size: 10,
            bold: false,
            italic: false,
          },
        },
      },
    ).loadData(data);

    setDone(true);
  };

  return (
    <div className="w-full pb-20">
      <input
        id="bill-upload"
        type="file"
        accept=".xlsx,.xls,.csv"
        onChange={handleFileUpload}
      />

      {error && <div style={{ color: 'red' }}>{error}</div>}

      <div className="w-full h-96 p-10">
        <div
          id="x-spreadsheet-demo"
          style={{ width: '100%', height: '100%' }}
        ></div>
      </div>
    </div>
  );
};

// Utility functions for converting between workbook and x-spreadsheet format
const stox = (wb) => {
  const data = [];
  wb.SheetNames.forEach((sheetName) => {
    const ws = wb.Sheets[sheetName];
    const sheetData = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      blankrows: false,
    });
    const rows = sheetData.map((row, rowIndex) => {
      const cells = Object.keys(row).map((col, colIndex) => ({
        text: row[col],
        editable: true,
      }));
      return { cells };
    });
    data.push({ name: sheetName, rows });
  });
  return data;
};

const xtos = (data) => {
  const wb = XLSX.utils.book_new();
  data.forEach((sheet) => {
    const ws = XLSX.utils.aoa_to_sheet(
      sheet.rows.map((row) => row.cells.map((cell) => cell.text)),
    );
    XLSX.utils.book_append_sheet(wb, ws, sheet.name);
  });
  return wb;
};

export default ExcelUpload;
